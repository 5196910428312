import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Documents = ({ auth }) => {
  const { t } = useTranslation('documents');
  const { documents } = auth.user;

  return (
    <Box>
      <Paper>
        <Box p={2}>
          <Typography variant="h5" gutterBottom>
            {t('documents:documents.title')}
          </Typography>

          <ul>
            {documents.map((document) => {
              return (
                <Typography component="li" key={document.name}>
                  <a href={document.url} target="_blank" rel="noreferrer">
                    {t(`documents:documents.types.${document.name}`)}
                  </a>
                </Typography>
              );
            })}
          </ul>
        </Box>
      </Paper>
    </Box>
  );
};

export default Documents;
