import { v2 } from '../axios';

export const STRIPE_METHOD = 'stripe';

export const computeFee = (plan, amount) => {
  const fee = (plan.fee / 100) * amount;
  return Math.floor(fee * 100) / 100;
};

export const getCommonPledgSettings = (user) => {
  const { addresses, patient } = user;
  const address = addresses.find((item) => item.type === 'main');
  return {
    amountCents: patient.price * 100,
    email: user.email,
    title: 'Traitement Joovence',
    reference: patient.reference,
    firstName: user.firstname,
    lastName: user.lastname,
    civility: user.gender === 'm' ? 'Mr' : 'Ms',
    address: {
      street: address.address,
      city: address.city,
      zipcode: address.postal_code,
      country: address.country
    },
    // format mobile
    phoneNumber: `+${user.mobile}`,
    showCloseButton: false
  };
};

export const attemptPaymentWithPledgECard = async (data) => {
  const { data: payment } = await v2.post('payments', data);

  return payment;
};
