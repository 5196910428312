import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import InvoiceContent from './InvoiceContent';
import { downloadPdf } from '../../utils/files';

const Quotation = ({ patient }) => {
  const { t } = useTranslation('invoices');

  const generatePdf = async () => {
    const { data: content } = await axios.get(`/quotation/generate-pdf`, {
      responseType: 'arraybuffer'
    });

    return downloadPdf(content, `devis-joovence.pdf`);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('invoices:invoices.quotation.title')}
        </Typography>
      </Grid>
      <InvoiceContent
        price={`${Number.parseFloat(patient.price).toFixed(2)} €`}
        onDownload={generatePdf}
        name={t('invoices:invoices.quotation.content.name')}
      />
    </>
  );
};

export default Quotation;
