import React from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';

const MySelect = ({
  input: { name, value, onChange, ...restInput },
  meta,
  label,
  onSelect,
  formControlProps: { fullWidth, restFormControlProps },
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (event) => {
    if (onSelect) {
      onSelect(event);
    }
    onChange(event);
  };

  return (
    <FormControl
      fullWidth={fullWidth}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restFormControlProps}
      error={showError}
    >
      <InputLabel htmlFor={name}>{label}</InputLabel>

      <Select
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        name={name}
        onChange={handleChange}
        inputProps={restInput}
        value={value}
      />

      {showError && (
        <FormHelperText>{meta.error || meta.submitError}</FormHelperText>
      )}
    </FormControl>
  );
};

export default MySelect;
