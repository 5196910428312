import React, { Component } from 'react';
import { Link, Switch, Route } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import parseISO from 'date-fns/parseISO';
import { withTranslation } from 'react-i18next';
import PhotosUpload from '../PhotosUpload';

import EditAligner from './EditAligner';

import axios from '../../axios';

class ViewAligner extends Component {
  state = {
    aligner: null
  };

  componentDidMount() {
    this.fetchAligner();
  }

  fetchAligner = async () => {
    const { match } = this.props;
    const { serie } = match.params;

    const { data: aligner } = await axios.get(`aligner-by-serie/${serie}`);

    this.setState({ aligner });
  };

  render() {
    const { patient, auth, snackbar, t } = this.props;

    const { aligner } = this.state;

    if (!aligner) {
      return null;
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Button size="small" component={Link} to="/traitement">
            {t('treatments:treatments.viewAligner.back.button.text')}
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Switch>
            <Route
              path="/traitement/:serie/edit"
              render={(props) => (
                <EditAligner
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  aligner={aligner}
                  fetchAligner={this.fetchAligner}
                  snackbar={snackbar}
                />
              )}
            />
            <Route
              render={() => (
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Typography variant="h6" component="h2" align="center">
                          {t('treatments:treatments.viewAligner.title')}{' '}
                          {aligner.serie}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography align="center" color="textSecondary">
                          {t(
                            'treatments:treatments.viewAligner.card.startedAt'
                          )}
                        </Typography>
                        <Typography align="center" variant="h6">
                          {t('common:common.date.detailed', {
                            date: parseISO(aligner.started_at)
                          })}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <Typography align="center" color="textSecondary">
                          {t(
                            'treatments:treatments.viewAligner.card.finishedAt'
                          )}
                        </Typography>
                        <Typography align="center" variant="h6">
                          {t('common:common.date.detailed', {
                            date: parseISO(
                              aligner.finished_at || aligner.expected_end
                            )
                          })}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography align="center" color="textSecondary">
                          {t('treatments:treatments.viewAligner.card.daysWorn')}
                        </Typography>
                        <Typography align="center" variant="h6">
                          {aligner.days_worn}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Button
                      size="small"
                      component={Link}
                      to={`/traitement/${aligner.serie}/edit`}
                      color="primary"
                    >
                      {t('treatments:treatments.viewAligner.edit.button.text')}
                    </Button>
                  </CardActions>
                </Card>
              )}
            />
          </Switch>
        </Grid>
        {!aligner.has_no_photos && (
          <Grid item xs={12}>
            <PhotosUpload
              patient={patient}
              serie={aligner.serie}
              auth={auth}
              snackbar={snackbar}
              readOnly={!!aligner.requested_review_at}
              onSuccess={this.fetchAligner}
              allowNoPhotos
              aligner={aligner}
            />
          </Grid>
        )}
        {!!aligner.has_no_photos && (
          <Grid item xs={12}>
            <Typography align="center">
              {t('treatments:treatments.viewAligner.hasNoPhotos.message')}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withTranslation(['treatments', 'common'])(ViewAligner);
