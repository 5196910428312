import React from 'react';

import FilePreviewImage from './FilePreviewImage';
import FilePreviewPdf from './FilePreviewPdf';

const TYPE_PDF = 'application/pdf';

const FilePreview = ({ file, description, fileName }) => {
  if (file.mime_type === TYPE_PDF) {
    return <FilePreviewPdf file={file} />;
  }
  return <FilePreviewImage src={file.url} alt={description || fileName} />;
};

export default FilePreview;
