/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-console */
import React from 'react';
import { Paper, Typography, Grid, Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { withStyles } from '@material-ui/core/styles';
import { parseISO } from 'date-fns';
import { Form } from 'react-final-form';
import { Trans, withTranslation } from 'react-i18next';
import Upload from '../Upload';
import { getAnglesFromVersion } from '../../constants/photos.constant';
import Loadable from '../Loadable';
import axios from '../../axios';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const AdditionalReview = ({
  additionalReview,
  aligner,
  patient,
  onReview,
  classes,
  t
}) => {
  const requiresReviewAt = t('common:common.date.date', {
    date: parseISO(additionalReview.required_review_at)
  });

  const angles = getAnglesFromVersion(aligner.version);
  const handleSubmit = async (values) => {
    try {
      const { data: review } = await axios.post(
        `additional-review/${additionalReview.id}/request-review`,
        values
      );
      await onReview(review);
    } catch (error) {
      console.error(error);
    }
  };
  const validate = () => {
    return {};
  };

  const renderStatus = () => {
    if (additionalReview.is_pending_review) {
      return (
        <>
          <Typography gutterBottom variant="h5">
            {t('treatments:treatments.additionalReview.isPendingReview.title')}
          </Typography>
          <Typography>
            <Trans
              t={t}
              i18nKey="treatments:treatments.additionalReview.isPendingReview.content"
            >
              Nous avons bien recu vos photos pour les
              <strong>
                aligneurs numéro
                {{ alignerNumber: aligner.serie }}
              </strong>
              , ils sont en cours d'évaluation. Il est important que vous
              continuiez à les porter, vous serez notifié par email et SMS pour
              passer aux prochains aligneurs.
            </Trans>
          </Typography>
        </>
      );
    }

    return additionalReview.can_request_review ? (
      <>
        <Typography gutterBottom variant="h5">
          {t('treatments:treatments.additionalReview.canRequestReview.title')}
        </Typography>
        <Typography>
          {t('treatments:treatments.additionalReview.canRequestReview.content')}
        </Typography>
      </>
    ) : (
      <>
        <Typography gutterBottom variant="h5">
          {t(
            'treatments:treatments.additionalReview.requestReviewNotReady.title'
          )}
        </Typography>
        <Typography>
          <Trans
            t={t}
            i18nKey="treatments:treatments.additionalReview.requestReviewNotReady.content"
          >
            Continuez à porter vos
            <strong>
              aligneurs
              {{ alignerNumber: aligner.serie }}
            </strong>
            en machant vos chewies jusqu'au
            <strong>{{ requiredDate: requiresReviewAt }}</strong>.
          </Trans>
        </Typography>
      </>
    );
  };

  return (
    <div>
      <Paper className={classes.root}>{renderStatus()}</Paper>
      {additionalReview.can_request_review && (
        <Form
          onSubmit={handleSubmit}
          validate={validate}
          render={({
            handleSubmit: onSubmit,
            submitting,
            hasValidationErrors,
            form
          }) => (
            <form onSubmit={onSubmit}>
              <Grid container spacing={3} justifyContent="center">
                {angles.map((angle, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Upload
                      receiverId={patient.id}
                      receiverType="patient"
                      resourceId={additionalReview.id}
                      resourceType="additional_review"
                      type="treatment_photo"
                      backgroundImage={angle.background}
                      meta={{
                        angle: angle.name,
                        version: aligner.version,
                        additional_review: 'true',
                        additional_review_id: additionalReview.id
                      }}
                      fileName={`photo-${angle.name}-${aligner.serie}-${patient.reference}`}
                      description={angle.display}
                      retryable
                      autoUpload
                      readOnly={additionalReview.is_pending_review}
                      onSuccess={() => {
                        form.change(angle.name, true);
                      }}
                      hint={
                        angle.angle && (
                          <img
                            alt={angle.display}
                            className={classes.hint}
                            src={angle.hint}
                          />
                        )
                      }
                    />
                  </Grid>
                ))}
                {!additionalReview.is_pending_review && (
                  <Grid item xs={12} sm={6}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={submitting || hasValidationErrors}
                      fullWidth
                    >
                      <Loadable loading={submitting} noMessage size={18}>
                        {t('common:common.send')}
                        <SendIcon className={classes.icon} />
                      </Loadable>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </form>
          )}
        />
      )}
    </div>
  );
};
const AdditionalReviewWithStyles = withStyles(styles)(AdditionalReview);
export default withTranslation(['treatments', 'common'])(
  AdditionalReviewWithStyles
);
