import React, { useState } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import { downloadPdf } from '../../utils/files';
import InvoiceAnnual from './InvoiceAnnual';
import InvoiceSemester from './InvoiceSemester';
import InvoiceSelectPeriodicity from './InvoiceSelectPeriodicity';

const TreatmentInvoice = ({ patient, invoice, localInvoice }) => {
  const { t } = useTranslation('invoices');

  const [periodicity, setPeriodicity] = useState('semester');

  const generatePdf = async (id, params, name) => {
    const { data: content } = await axios.get(`/invoice/${id}/generate-pdf`, {
      params,
      responseType: 'arraybuffer'
    });

    return downloadPdf(content, name);
  };

  const handleChangePeriodicity = (event) => {
    setPeriodicity(event.target.value);
  };

  const isMild = patient.treatment_type === 'mild';

  return (
    <>
      {isMild ? (
        <Grid item xs={12}>
          <Typography gutterBottom>
            {t('invoices:invoices.treatmentInvoice.message')}
          </Typography>
        </Grid>
      ) : (
        <InvoiceSelectPeriodicity
          periodicity={periodicity}
          onChange={handleChangePeriodicity}
        />
      )}
      {periodicity === 'annual' ? (
        <InvoiceAnnual invoice={invoice} generatePdf={generatePdf} />
      ) : (
        <InvoiceSemester
          invoice={invoice}
          generatePdf={generatePdf}
          localInvoice={localInvoice}
        />
      )}
    </>
  );
};

export default TreatmentInvoice;
