import styled from 'styled-components';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio
} from '@material-ui/core';

export const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

export const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 16px;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 5px;
  width: 100%;
  margin: 0 !important;
  padding: 8px;
  min-height: 60px;

  .MuiFormControlLabel-label {
    font-size: 0.85rem;
  }

  ${({ theme, checked }) =>
    checked &&
    `
    border-width: 3px;
    border-color: ${theme.palette.primary.main};
    font-weight: 600;

    .MuiFormControlLabel-label {
      font-size: 0.85rem;
      font-weight: 500;
    }
  `}
`;

export const StyledRadio = styled(Radio)`
  display: none;
`;

export const StyledPaymentMethodContainer = styled(Grid)`
  ${({ hidden }) => (hidden ? `display: none;` : 'display: block;')}
`;

export const StyledPledgContainer = styled(Grid)`
  ${({ hidden }) => (hidden ? `display: none;` : 'display: block;')}
`;
