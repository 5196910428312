import React from 'react';
import Radio from '@material-ui/core/Radio';

const MyRadio = ({
  input: { checked, value, name, onChange, ...restInput },
  meta,
  ...rest
}) => (
  <Radio
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    name={name}
    inputProps={restInput}
    onChange={onChange}
    checked={!!checked}
    value={value}
  />
);

export default MyRadio;
