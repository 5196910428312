import React from 'react';

import axios from '../../axios';
import InvoiceContent from './InvoiceContent';
import { downloadPdf } from '../../utils/files';

const Invoice = ({ invoice, patient }) => {
  const generatePdf = async () => {
    const { data: content } = await axios.get(
      `/invoice/${invoice.id}/generate-pdf`,
      {
        responseType: 'arraybuffer'
      }
    );

    return downloadPdf(content, `facture.pdf`);
  };

  return (
    <InvoiceContent
      price={`${Number.parseFloat(patient.booking.price).toFixed(2)} €`}
      onDownload={generatePdf}
      name="Facture"
    />
  );
};

export default Invoice;
