import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Box } from '@material-ui/core';
import AppBar from './AppBar';
import Drawer from './Drawer';
import Content from './Content';
import RedirectToAuth from './RedirectToAuth';
import LoaderLogo from './LoaderLogo';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: '100vh',
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%'
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3)
  },
  loaderContainer: {
    backgroundColor: `#FAACA8`,
    backgroundImage: `linear-gradient(19deg, #FAACA8 0%, #DDD6F3 100%)`
  }
}));

const Authenticated = ({ auth }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();

  const handleDrawerToggle = () => {
    setMobileOpen((open) => !open);
  };

  if (!auth.fetchedUser) {
    return (
      <Box
        height="100vh"
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.loaderContainer}
      >
        <LoaderLogo />
      </Box>
    );
  }

  if (!auth.user) {
    return <RedirectToAuth />;
  }

  // const needsToCompleteProfile = !auth.user.birthday;
  if (!auth.user.birthday) {
    return <RedirectToAuth to="/profile/personal-data/edit" />;
  }

  return (
    <div className={classes.root}>
      <AppBar handleDrawerToggle={handleDrawerToggle} auth={auth} />
      <Drawer
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        auth={auth}
      />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {auth.user && <Content auth={auth} />}
      </main>
    </div>
  );
};

export default Authenticated;
