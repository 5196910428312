import React from 'react';
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const LABEL_WIDTH = 77;

const InvoiceSelectPeriodicity = ({ periodicity, onChange }) => {
  const { t } = useTranslation('invoices');

  return (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom>
          {t(
            'invoices:invoices.invoiceSelectPeriodicity.invoiceSelectPeriodicity'
          )}
        </Typography>
        <Typography gutterBottom>
          {t(
            'invoices:invoices.invoiceSelectPeriodicity.invoiceUnlocksAfter6months'
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">
            {t('invoices:invoices.invoiceSelectPeriodicity.form.label')}
          </InputLabel>
          <Select
            native
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={periodicity}
            onChange={onChange}
            labelWidth={LABEL_WIDTH}
          >
            <option value="semester">
              {t(
                'invoices:invoices.invoiceSelectPeriodicity.form.options.semester'
              )}
            </option>
            <option value="annual">
              {t(
                'invoices:invoices.invoiceSelectPeriodicity.form.options.annual'
              )}
            </option>
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default InvoiceSelectPeriodicity;
