import React, { useState } from 'react';
import Script from 'react-load-script';
import Loadable from '../Loadable';

const ScriptLoader = ({ url, children, onLoad }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      <Script
        url={url}
        onLoad={async () => {
          if (onLoad) {
            await onLoad();
          }
          setLoaded(true);
        }}
      />
      {loaded ? children : <Loadable loading />}
    </>
  );
};

export default ScriptLoader;
