import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';

import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import parseISO from 'date-fns/parseISO';
import Script from 'react-load-script';
import { withTranslation } from 'react-i18next';
import axios from '../../axios';
import Loadable from '../Loadable';
import Select from '../form/Select';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

class Main extends Component {
  state = { stripeLoaded: false, skus: [], retainers: [] };

  // eslint-disable-next-line react/sort-comp
  fetchSkus = async () => {
    const { data: skus } = await axios.get('retainers/skus');

    this.setState({ skus });
  };

  fetchRetainers = async () => {
    const { data: retainers } = await axios.get('retainers');

    this.setState({ retainers });
  };

  componentDidMount() {
    this.fetchRetainers();
    this.fetchSkus();
    this.checkPaymentSuccess();
  }

  checkPaymentSuccess = async () => {
    const url_string = window.location.href;
    const url = new URL(url_string);
    const content = url.searchParams.get('success_msg');
    const { snackbar, t } = this.props;

    if (content === 'retainers') {
      snackbar.open(t('retainer:retainer.snackbar.success'));
    }
  };

  handleSubmit = async (values) => {
    const { auth } = this.props;

    try {
      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC);

      stripe
        .redirectToCheckout({
          items: [{ sku: values.sku_id, quantity: 1 }],
          clientReferenceId: auth.user.patient.reference,
          customerEmail: auth.user.email,
          billingAddressCollection: 'required',

          // Do not rely on the redirect to the successUrl for fulfilling
          // purchases, customers may not always reach the success_url after
          // a successful payment.
          // Instead use one of the strategies described in
          // https://stripe.com/docs/payments/checkout/fulfillment
          successUrl: `http://${window.location.hostname}/contention?success_msg=retainers`,
          cancelUrl: `http://${window.location.hostname}/contention`
        })
        .then((result) => {
          if (result.error) {
            // If `redirectToCheckout` fails due to a browser or network
            // error, display the localized error message to your customer.
          }
        });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  render() {
    const { classes, t } = this.props;
    const { stripeLoaded, retainers, skus } = this.state;

    return (
      <Paper className={classes.root}>
        <Typography variant="h5">{t('retainer:retainer.title')}</Typography>

        <Loadable loading={!stripeLoaded || !skus.length}>
          <Form
            onSubmit={this.handleSubmit}
            initialValues={{
              sku_id: skus.length && skus[0].id
            }}
            render={({ submitting, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} sm={8} md={6}>
                      <Field
                        name="sku_id"
                        label="Arcade"
                        formControlProps={{
                          fullWidth: true
                        }}
                        component={Select}
                      >
                        {skus.map((sku) => (
                          <MenuItem key={sku.id} value={sku.id}>
                            {`${sku.attributes.name} - ${sku.price / 100} €`}
                          </MenuItem>
                        ))}
                      </Field>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} sm={8} md={6}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={submitting}
                      >
                        {t('retainer:retainer.order')}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              );
            }}
          />
        </Loadable>
        <br />
        <br />
        <Grid container>
          {retainers.map((retainer) => (
            <Grid item xs={12}>
              <Typography gutterBottom variant="subtitle1">
                {retainer.sku.attributes.name} -{' '}
                {t('retainer:retainer.orderedAt')}{' '}
                {t('common:common.date.date', {
                  date: parseISO(retainer.created_at)
                })}
              </Typography>
            </Grid>
          ))}
        </Grid>

        <Script
          url="https://js.stripe.com/v3/"
          onLoad={() => {
            this.setState({ stripeLoaded: true });
          }}
        />
      </Paper>
    );
  }
}
const MainWithStyles = withStyles(styles)(Main);
export default withTranslation(['retainer', 'common'])(MainWithStyles);
