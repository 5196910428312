/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import SaveAltIcon from '@material-ui/icons/SaveAlt';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Loadable from './Loadable';

import FilePreview from './file/FilePreview';

const styles = (theme) => ({
  thumbnailContainer: {
    width: '100%',
    height: 150,
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fab: {
    margin: theme.spacing()
  }
});

class Download extends Component {
  render() {
    const {
      file,
      classes,
      description,
      fileName,
      isFetching,
      actionButtons,
      t
    } = this.props;

    if (!file || Object.keys(file).length === 0) {
      return null;
    }

    return (
      <div className={classes.root}>
        <div
          className={classes.thumbnailContainer}
          onClick={this.stream}
          onKeyPress={this.stream}
          role="button"
          tabIndex={0}
        >
          <Loadable loading={isFetching}>
            <a href={file.url} target="_blank" rel="noopener noreferrer">
              <FilePreview
                file={file}
                description={description}
                fileName={fileName}
              />
            </a>
          </Loadable>
        </div>
        <Tooltip title={t('common:common.downloadTooltip')} placement="bottom">
          <Fab
            color="secondary"
            size="small"
            href={file.url}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.fab}
          >
            <SaveAltIcon />
          </Fab>
        </Tooltip>
        {actionButtons}
      </div>
    );
  }
}

Download.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.object,
  fileName: PropTypes.string,
  description: PropTypes.string.isRequired
};
const DownloadWithStyles = withStyles(styles)(Download);
export default withTranslation('common')(DownloadWithStyles);
