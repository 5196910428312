/* eslint-disable no-console */
import axios, { v2 } from '../axios';

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC;

export const createCheckoutSessionForBooking = async (booking) => {
  try {
    const { data } = await axios.post('bookings/checkout-session', booking);

    const stripe = window.Stripe(STRIPE_PUBLIC_KEY);

    stripe
      .redirectToCheckout({
        sessionId: data.id
      })
      .then(() => {});
  } catch (error) {
    console.log(error);
  }
};

export const createCheckoutSessionForBookingRequest = async (
  bookingRequest
) => {
  try {
    const { data } = await v2.post('checkout-sessions', {
      booking_request_code: bookingRequest.code
    });

    const stripe = window.Stripe(STRIPE_PUBLIC_KEY);

    stripe
      .redirectToCheckout({
        sessionId: data.id
      })
      .then((result) => {
        console.log(result);
      });
  } catch (error) {
    console.log(error);
  }
};
