/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import parseISO from 'date-fns/parseISO';

import Script from 'react-load-script';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Grid } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import DialogWithButton from '../DialogWithButton';
import DoctolibConfirm from './DoctolibConfirm';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  iframe: {
    height: '304px',
    minHeight: '304px',
    width: '100%'
  },
  iframeContainer: {
    padding: 0
  },
  instructions: { padding: theme.spacing(2) },
  highlight: {
    textDecoration: 'underline',
    fontWeight: 'bold'
  },
  actions: {
    justifyContent: 'space-between'
  }
});

const Doctolib = ({
  practician,
  classes,
  handleClose,
  open,
  booking,
  setBooking,
  availabilities,
  getHours,
  onBook
}) => {
  const doctolibAccount = practician.agenda.metadata;
  const { t } = useTranslation(['bookings', 'common']);
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="doctolib-iframe"
      scroll="body"
    >
      <DialogTitle id="doctolib-iframe" align="center">
        {t('bookings:bookings.doctolib.title')} {practician.alias}
      </DialogTitle>

      <div className={classes.instructions}>
        {booking && (
          <DialogContentText color="secondary">
            <Trans t={t} i18nKey="bookings:bookings.doctolib.dateToChoose">
              - Selectionnez le{' '}
              <span className={classes.highlight}>
                {{
                  date: t('common:common.date.detailed', {
                    date: parseISO(booking.date)
                  })
                }}
              </span>{' '}
              à {{ hour: booking.hour }} sur le calendrier en bas de page
            </Trans>
          </DialogContentText>
        )}
        <DialogContentText color="secondary">
          {t('bookings:bookings.doctolib.createDoctolibAccount')}
        </DialogContentText>
        <DialogContentText color="secondary">
          <Trans
            t={t}
            i18nKey="bookings:bookings.doctolib.appointmentValidateOnDoctolib"
          >
            <span>
              - ATTENTION : Une fois votre RDV validé sur Doctolib, cliquez sur
              le bouton
            </span>
            <span className={classes.highlight}>J'ai pris rendez-vous</span>
            <span>en bas de page</span>
          </Trans>
        </DialogContentText>
      </div>

      <DialogContent className={classes.iframeContainer}>
        <iframe
          title={practician.alias}
          src={`${doctolibAccount.iframe_url}?vmid=${doctolibAccount.visit_motive_id}&locale=fr`}
          className={classes.iframe}
          frameBorder="0"
          scrolling="no"
        />
        <Script
          url="https://www.doctolib.fr/js/iframeResizer.js"
          onLoad={() => {
            try {
              window.iFrameResize();
            } catch (error) {
              // eslint-disable-next-line no-console
              console.error(error);
            }
          }}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Button onClick={handleClose} color="secondary" fullWidth>
              {t('bookings:bookings.doctolib.cancel')}
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DialogWithButton
              onAccept={onBook}
              buttonProps={{
                text: t('bookings:bookings.doctolib.confirm'),
                style: { color: '#000', backgroundColor: '#f3d937' },
                fullWidth: true
              }}
              dialogProps={{
                title: t('bookings:bookings.doctolib.confirmDialog.title'),
                description: (
                  <span>
                    {booking &&
                      t(
                        'bookings:bookings.doctolib.confirmDialog.description',
                        {
                          date: parseISO(booking.date),
                          hour: booking.hour
                        }
                      )}
                    <br />
                    <br />
                  </span>
                ),
                content: (
                  <DoctolibConfirm
                    booking={booking}
                    setBooking={setBooking}
                    availabilities={availabilities}
                    getHours={getHours}
                  />
                )
              }}
              confirmButtonProps={{
                text: t('bookings:bookings.doctolib.confirmDialog.confirm'),
                variant: 'contained'
              }}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(Doctolib);
