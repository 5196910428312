import React from 'react';
import { useTranslation } from 'react-i18next';
import InvoiceContent from './InvoiceContent';

const InvoiceSemester = ({ invoice, generatePdf, localInvoice }) => {
  const { t } = useTranslation('invoices');

  return (
    <>
      <InvoiceContent
        price={`${Number.parseFloat(invoice.amount / 100 / 2).toFixed(2)} €`}
        onDownload={async () => {
          await generatePdf(
            invoice.id,
            {
              periodicity: 'semester',
              semester: 1
            },
            'facture-traitement-semestre-1.pdf'
          );
        }}
        name={t('invoices:invoices.invoiceSemester.semester1.name')}
      />
      {invoice.can_download_semester_2 && (
        <InvoiceContent
          price={`${Number.parseFloat(invoice.amount / 100 / 2).toFixed(2)} €`}
          onDownload={async () => {
            await generatePdf(
              invoice.id,
              {
                periodicity: 'semester',
                semester: 2
              },
              'facture-traitement-semestre-2.pdf'
            );
          }}
          name={t('invoices:invoices.invoiceSemester.semester2.name')}
        />
      )}
      {localInvoice && localInvoice.can_download_semester_3 && (
        <InvoiceContent
          price={`${Number.parseFloat(localInvoice.amount / 100).toFixed(2)} €`}
          onDownload={async () => {
            await generatePdf(
              localInvoice.id,
              {
                periodicity: 'semester',
                semester: 3
              },
              'facture-traitement-semestre-3.pdf'
            );
          }}
          name={t('invoices:invoices.invoiceSemester.semester3.name')}
        />
      )}
    </>
  );
};

export default InvoiceSemester;
