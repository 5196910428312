import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Rating from '@material-ui/lab/Rating';

import { TextField } from 'final-form-material-ui';

import Grid from '@material-ui/core/Grid';

import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

const AlignerRating = ({ aligner, readOnly, form }) => {
  const { t } = useTranslation('alignerRating');
  const marksFitting = [
    {
      value: 0,
      label: t('alignerRating:alignerRating.marksFitting.loose')
    },
    {
      value: 1
    },
    {
      value: 2
    },
    {
      value: 3
    },
    {
      value: 4
    },
    {
      value: 5,
      label: t('alignerRating:alignerRating.marksFitting.normal')
    },
    {
      value: 6
    },
    {
      value: 7
    },
    {
      value: 8
    },
    {
      value: 9
    },
    {
      value: 10,
      label: t('alignerRating:alignerRating.marksFitting.tight')
    }
  ];

  const marksPain = [
    {
      value: 0,
      label: t('alignerRating:alignerRating.marksFitting.painless')
    },
    {
      value: 1
    },
    {
      value: 2
    },
    {
      value: 3
    },
    {
      value: 4
    },
    {
      value: 5
    },
    {
      value: 6
    },
    {
      value: 7
    },
    {
      value: 8
    },
    {
      value: 9
    },
    {
      value: 10,
      label: t('alignerRating:alignerRating.marksFitting.painful')
    }
  ];
  const [globalSatisfaction, setGlobalSatisfaction] = useState(
    (readOnly && aligner.global_satisfaction) || 5
  );

  if (
    readOnly &&
    !aligner.global_satisfaction &&
    !aligner.global_satisfaction &&
    !aligner.pain_lower &&
    !aligner.pain_upper &&
    !aligner.fitting_lower &&
    !aligner.fitting_upper &&
    !aligner.remarks
  )
    return null;

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="subtitle2">
          {t('alignerRating:alignerRating.title')}
          {aligner.serie}{' '}
        </Typography>

        <Box p={4}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Typography gutterBottom>
                {t('alignerRating:alignerRating.globalSatisfaction')}
              </Typography>

              <Rating
                size="large"
                name="global_satisfaction"
                value={globalSatisfaction}
                onChange={(event, value) => {
                  setGlobalSatisfaction(value);
                  form.change('global_satisfaction', value);
                }}
                readOnly={readOnly}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom>
                {`${t('alignerRating:alignerRating.feeling.title')} - ${t(
                  'alignerRating:alignerRating.feeling.fittingLower'
                )}`}
              </Typography>
              <Slider
                defaultValue={aligner.fitting_lower || 5}
                min={0}
                max={10}
                step={1}
                valueLabelDisplay="on"
                marks={marksFitting}
                onChange={(e, value) => {
                  form.change('fitting_lower', value);
                }}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom>
                {`${t('alignerRating:alignerRating.feeling.title')} - ${t(
                  'alignerRating:alignerRating.feeling.fittingUpper'
                )}`}
              </Typography>

              <Slider
                defaultValue={aligner.fitting_upper || 5}
                min={0}
                max={10}
                step={1}
                valueLabelDisplay="on"
                marks={marksFitting}
                onChange={(e, value) => {
                  form.change('fitting_upper', value);
                }}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom>
                {`${t('alignerRating:alignerRating.comfort.title')} - ${t(
                  'alignerRating:alignerRating.comfort.painLower'
                )}`}
              </Typography>

              <Slider
                defaultValue={aligner.pain_lower || 0}
                min={0}
                max={10}
                step={1}
                valueLabelDisplay="on"
                marks={marksPain}
                onChange={(e, value) => {
                  form.change('pain_lower', value);
                }}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Typography gutterBottom>
                {`${t('alignerRating:alignerRating.comfort.title')} - ${t(
                  'alignerRating:alignerRating.comfort.painUpper'
                )}`}
              </Typography>
              <Slider
                defaultValue={aligner.pain_upper || 0}
                min={0}
                max={10}
                step={1}
                valueLabelDisplay="on"
                marks={marksPain}
                onChange={(e, value) => {
                  form.change('pain_upper', value);
                }}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="remarks"
                component={TextField}
                type="text"
                label={t('alignerRating:alignerRating.remarks')}
                variant="outlined"
                fullWidth
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};
export default AlignerRating;
