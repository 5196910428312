/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import { Box, Checkbox, Paper, TableCell, Typography } from '@material-ui/core';
import { parseISO } from 'date-fns';
import { SelectionState } from '@devexpress/dx-react-grid';
import {
  Grid as GridX,
  Table,
  VirtualTable,
  TableHeaderRow,
  TableSelection
} from '@devexpress/dx-react-grid-material-ui';

import { useTranslation, Trans } from 'react-i18next';
import SnackbarContext from '../SnackbarContext';
import DialogWithButton from '../DialogWithButton';
import { formatNumberToCurrency } from '../../utils/payment';
import { v2 } from '../../axios';

const isAvailableForReward = (affiliation) =>
  affiliation.affiliable?.paid_at && !affiliation.referrer_treatment_claimed_at;

const SelectionCell = ({ selected, onToggle, row, canClaimReward }) => {
  if (!isAvailableForReward(row) || !canClaimReward) {
    return <TableCell />;
  }

  return (
    <TableCell size="small">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Checkbox checked={selected} onChange={onToggle} />
      </Box>
    </TableCell>
  );
};

const getRowId = (row) => row.id;

const Affiliations = ({ referral }) => {
  const { t } = useTranslation(['referral', 'common']);

  const snackbar = useContext(SnackbarContext);

  const [affiliations, setAffiliations] = useState([]);
  const [selection, setSelection] = useState([]);

  const fetchAffiliations = async () => {
    try {
      const { data: fetchedAffiliations } = await v2.get('affiliations');
      setAffiliations(fetchedAffiliations);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAffiliations();
  }, []);

  const claimReward = async () => {
    try {
      await v2.post('/affiliations/reward', { ids: selection });
      await fetchAffiliations();
      snackbar.open(t('referral:referral.affiliations.snackbar.success'));
    } catch ({ response }) {
      if (!response) {
        return;
      }
      snackbar.open(
        t('referral:referral.affiliations.snackbar.error'),
        'error'
      );
    } finally {
      setSelection([]);
    }
  };

  if (!affiliations.length) return null;

  const sumReward =
    affiliations
      .filter((a) => selection.includes(a.id))
      .reduce((p, c) => p + c.referrer_treatment_amount, 0) / 100;

  const availableForReward = affiliations.filter(
    (a) => referral?.can_claim_reward && isAvailableForReward(a)
  );

  return (
    <Paper>
      <Box pl={2} pt={2}>
        {!!availableForReward.length && (
          <Box>
            {selection.length !== 0 ? (
              <DialogWithButton
                onAccept={claimReward}
                buttonProps={{
                  text: t('referral:referral.affiliations.dialog.button.text')
                }}
                dialogProps={{
                  title: t('referral:referral.affiliations.dialog.title'),
                  description: (
                    <Trans
                      t={t}
                      i18nKey="referral:referral.affiliations.dialog.description"
                    >
                      Recevoir mon chèque cadeau de
                      <strong>
                        {{ reward: formatNumberToCurrency(sumReward) }}
                      </strong>
                      euros. Voulez-vous confirmer?
                    </Trans>
                  )
                }}
                confirmButtonProps={{
                  text: t(
                    'referral:referral.affiliations.dialog.confirmButton.text'
                  ),
                  variant: 'contained'
                }}
              />
            ) : (
              <Typography>
                {t('referral:referral.affiliations.message')}
              </Typography>
            )}
          </Box>
        )}
        <GridX
          rows={affiliations}
          columns={[
            {
              name: 'patient',
              title: t('referral:referral.affiliations.list.table.name'),
              getCellValue: (affiliation) =>
                `${affiliation.affiliable.fullname}`
            },
            {
              name: 'date',
              title: t('referral:referral.affiliations.list.table.date'),
              getCellValue: (affiliation) => {
                return `${t('common:common.date.date', {
                  date: parseISO(affiliation.created_at)
                })} - ${
                  !affiliation.affiliable.paid_at
                    ? t(
                        'referral:referral.affiliations.list.status.affiliateNotPaid'
                      )
                    : t(
                        'referral:referral.affiliations.list.status.affiliatePaid'
                      )
                }`;
              }
            },
            {
              name: 'reward',
              title: t('referral:referral.affiliations.list.table.reward'),
              getCellValue: (affiliation) => {
                let rewardStatus = affiliation.referrer_treatment_claimed_at
                  ? t(
                      'referral:referral.affiliations.list.status.referrerClaimedReward'
                    )
                  : t(
                      'referral:referral.affiliations.list.status.referrerDidNotClaimedReward'
                    );

                if (!affiliation.affiliable.paid_at) {
                  rewardStatus = t(
                    'referral:referral.affiliations.list.status.rewardNotAvailable'
                  );
                }
                return `${formatNumberToCurrency(
                  affiliation.referrer_treatment_amount / 100
                )} (${rewardStatus})`;
              }
            }
          ]}
          getRowId={getRowId}
        >
          <SelectionState
            selection={selection}
            onSelectionChange={setSelection}
          />
          <Table />
          <VirtualTable />
          <TableHeaderRow />
          <TableSelection
            cellComponent={(props) => (
              <SelectionCell
                canClaimReward={referral?.can_claim_reward}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
              />
            )}
          />
        </GridX>
      </Box>
    </Paper>
  );
};

export default Affiliations;
