/* eslint-disable react/no-unescaped-entities */
import { FormControlLabel, Grid } from '@material-ui/core';
import React from 'react';
import { Field } from 'react-final-form';
import { Trans, useTranslation } from 'react-i18next';
import Checkbox from '../../form/Checkbox';

const Confirm = ({ submitting }) => {
  const { t } = useTranslation('bookings');
  return (
    <>
      <Grid item xs={12}>
        <Field
          name="cgu"
          disabled={submitting}
          label={
            <span>
              <Trans
                t={t}
                i18nKey="bookings:bookings.practicians.confirmation.cgu"
              >
                J'accepte les
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.joovence.com/cgu"
                >
                  Conditions Générales d'Utilisation
                </a>
                de Joovence
              </Trans>
            </span>
          }
          type="checkbox"
          render={({ label, ...rest }) => {
            return (
              <FormControlLabel
                // eslint-disable-next-line react/jsx-props-no-spreading
                control={<Checkbox {...rest} />}
                label={label}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="cgv"
          disabled={submitting}
          label={
            <span>
              <Trans
                t={t}
                i18nKey="bookings:bookings.practicians.confirmation.cgv"
              >
                J'accepte les
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.joovence.com/cgv-patient"
                >
                  Conditions Générales Vente
                </a>
                de Joovence
              </Trans>
            </span>
          }
          type="checkbox"
          render={({ label, ...rest }) => {
            return (
              <FormControlLabel
                // eslint-disable-next-line react/jsx-props-no-spreading
                control={<Checkbox {...rest} />}
                label={label}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="allow_practician_transfer_impression"
          disabled={submitting}
          label={t(
            'bookings:bookings.practicians.confirmation.allowPracticianTransferImpression'
          )}
          type="checkbox"
          render={({ label, ...rest }) => {
            return (
              <FormControlLabel
                // eslint-disable-next-line react/jsx-props-no-spreading
                control={<Checkbox {...rest} />}
                label={label}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          name="allow_practician_transfer_data"
          disabled={submitting}
          label={t(
            'bookings:bookings.practicians.confirmation.allowPracticianTransferData'
          )}
          type="checkbox"
          render={({ label, ...rest }) => {
            return (
              <FormControlLabel
                // eslint-disable-next-line react/jsx-props-no-spreading
                control={<Checkbox {...rest} />}
                label={label}
              />
            );
          }}
        />
      </Grid>
    </>
  );
};

export default Confirm;
