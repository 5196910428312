import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const DoctolibConfirmWarning = ({ booking }) => {
  const { t } = useTranslation('bookings');

  if (!booking.practician.agenda) return null;

  return (
    <Box mt={2} mx={2}>
      <Typography align="center" color="error" gutterBottom>
        {t('bookings:bookings.viewBooking.doctolib.confirm.message', {
          name: booking.practician.user.lastname
        })}
      </Typography>
      <Typography align="center" color="error">
        <a
          href={`${booking.practician.agenda.metadata.iframe_url}?vmid=${booking.practician.agenda.metadata.visit_motive_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {t('bookings:bookings.viewBooking.doctolib.confirm.action')}
        </a>
      </Typography>
    </Box>
  );
};
