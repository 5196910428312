import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DialogWithButton from '../../DialogWithButton';
import { formatPhoneNumber } from '../../../utils/phone.utils';

const MoreAvailabilities = ({ practician }) => {
  const { t } = useTranslation('bookings');
  return (
    <DialogWithButton
      buttonProps={{
        text: t('bookings:bookings.practicians.moreAvailabilities.open'),
        fullWidth: true,
        variant: 'outlined'
      }}
      dialogProps={{
        title: t('bookings:bookings.practicians.moreAvailabilities.title'),
        description: t(
          'bookings:bookings.practicians.moreAvailabilities.description'
        ),
        content: (
          <>
            <Typography gutterBottom>
              <Typography gutterBottom>
                {t(
                  'bookings:bookings.practicians.moreAvailabilities.callDoctorDirectly'
                )}
              </Typography>
              <Typography gutterBottom>
                {t(
                  'bookings:bookings.practicians.moreAvailabilities.getSmsAftercall'
                )}
              </Typography>
              <Typography variant="h6">
                <span>
                  {t(
                    'bookings:bookings.practicians.moreAvailabilities.officeNumber'
                  )}
                </span>
                <a href={`tel:+${practician.landline}`}>
                  {formatPhoneNumber(
                    practician.landline,
                    practician.address.country
                  )}
                </a>
              </Typography>
            </Typography>
          </>
        )
      }}
      cancelButtonProps={{
        text: 'Fermer',
        variant: 'contained'
      }}
    />
  );
};

export default MoreAvailabilities;
