import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';
import Doctolib from '../Doctolib';
import axios, { v2Base } from '../../../axios';
import PracticianPanel from './PracticianPanel';
import { shouldUserPayDeposit } from '../../../utils/bookingRequests.utils';

const Practician = ({
  toConfirm,
  practician,
  setActivePractician,
  snackbar,
  t,
  onBook,
  auth,
  createCheckoutSession,
  activePractician,
  index
}) => {
  const [doctolibOpened, setDoctolibOpened] = useState(false);
  const [bookingData, setBookingData] = useState(null);
  const [availabilities, setAvailabilities] = useState(null);

  const fetchAvailabilities = async () => {
    try {
      const { data: fetchedAvailabilities } = await v2Base.get(
        `/practicians/${practician.id}/availabilities`
      );

      setAvailabilities(fetchedAvailabilities);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAvailabilities();
  }, [practician.id]);

  useEffect(() => {
    if (!toConfirm) return;
    if (practician.id.toString() !== toConfirm.practician_id) return;

    setBookingData({
      hour: toConfirm.hour,
      date: toConfirm.date,
      practician_id: toConfirm.practician_id
    });

    setActivePractician(practician.id);
    setDoctolibOpened(true);
  }, []);

  const createBooking = async (data) => {
    try {
      const { data: booking } = await axios.post('bookings', data);

      onBook(booking);
    } catch (error) {
      if (error.response && error.response.data.code === 'slot_not_available') {
        snackbar.open(error.response.data.message, 'error');
      } else {
        snackbar.open(
          t('bookings:bookings.practician.snackbar.error'),
          'error'
        );
      }
    }
  };

  const onSubmit = async (values) => {
    const data = {
      ...values
    };

    // refresh user to handle redirection from stripe checkout
    await auth.fetchUser();
    const { patient } = auth.user;

    if (
      patient &&
      !patient.booking_deposit_paid_at &&
      shouldUserPayDeposit(patient.user)
    ) {
      return createCheckoutSession(data);
    }

    if (practician.availabilities_source === 'doctolib') {
      setBookingData(data);

      return setDoctolibOpened(true);
    }

    return createBooking(data);
  };

  const getHours = (date) => {
    const day = availabilities.find(
      (availability) => availability.date === date
    );

    if (!day) {
      return [];
    }

    return day.slots.sort();
  };

  const handlePanelExpand = (expanded, practicianId) => {
    // eslint-disable-next-line no-param-reassign
    practicianId = expanded ? practicianId : null;

    return setActivePractician(practicianId);
  };

  const handleBooking = async () => {
    if (toConfirm) {
      await onBook(bookingData);
      return;
    }

    await createBooking(bookingData);
  };

  return (
    <>
      {practician.availabilities_source === 'doctolib' && (
        <Doctolib
          getHours={getHours}
          onSubmit={onSubmit}
          onPanelChange={handlePanelExpand}
          availabilities={availabilities ?? []}
          index={index}
          handleClose={() => setDoctolibOpened(false)}
          open={doctolibOpened}
          booking={bookingData}
          setBooking={setBookingData}
          onBook={handleBooking}
          practician={practician}
        />
      )}
      <PracticianPanel
        getHours={getHours}
        onSubmit={onSubmit}
        onPanelChange={handlePanelExpand}
        activePractician={activePractician}
        index={index}
        practician={practician}
        availabilities={availabilities}
        auth={auth}
      />
    </>
  );
};

Practician.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  practician: PropTypes.object.isRequired
};

export default withTranslation(['bookings'])(Practician);
