import axios, { axiosWithoutCredentials } from '../axios';

export const uploadToS3 = async (file) => {
  const extension = file.name.split('.').pop();
  const response = await axios.post(
    '/vapor/signed-storage-url',
    {
      content_type: file.type,
      metadata: {
        'client-extension': extension
      }
    },
    {
      baseURL: process.env.REACT_APP_API_URL
    }
  );

  const { headers } = response.data;

  if ('Host' in headers) {
    delete headers.Host;
  }

  const formattedHeaders = Object.keys(headers).reduce((previous, name) => {
    if (!Array.isArray(headers[name])) {
      return { ...previous, [name]: headers[name] };
    }
    return { ...previous, [name]: headers[name][0] };
  }, {});

  await axiosWithoutCredentials.put(response.data.url, file, {
    headers: formattedHeaders
  });

  response.data.extension = extension;

  return response.data;
};
