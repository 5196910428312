import occlussalUpper from '../assets/images/occlussal-upper.png';
import occlussalUpperExample from '../assets/images/occlussal-upper-example.png';
import occlussalLower from '../assets/images/occlussal-lower.png';
import occlussalLowerExample from '../assets/images/occlussal-lower-example.png';
import buccalLeft from '../assets/images/buccal-left.png';
import buccalLeftExample from '../assets/images/buccal-left-example.png';
import buccalRight from '../assets/images/buccal-right.png';
import buccalRightExample from '../assets/images/buccal-right-example.png';
import labialAnterior from '../assets/images/labial-anterior.png';
import labialAnteriorExample from '../assets/images/labial-anterior-example.png';

import buccalLeftOpenAligners from '../assets/images/buccal_left_open_aligners.png';
import buccalLeftOpen from '../assets/images/buccal_left_open.png';
import buccalRightOpenAligners from '../assets/images/buccal_right_open_aligners.png';
import buccalRightOpen from '../assets/images/buccal_right_open.png';
import labialAnteriorOpenAligners from '../assets/images/labial_anterior_open_aligners.png';
import labialAnteriorOpen from '../assets/images/labial_anterior_open.png';

export const VERSION_INITIAL = 'initial';
export const VERSION_WITH_ALIGNERS = 'with_aligners';

export const angles = [
  {
    name: 'occlussal_upper',
    background: occlussalUpper,
    hint: occlussalUpperExample,
    display: 'Arcade du haut'
  },
  {
    name: 'occlussal_lower',
    background: occlussalLower,
    hint: occlussalLowerExample,
    display: 'Arcade du bas'
  },
  {
    name: 'buccal_left',
    background: buccalLeft,
    hint: buccalLeftExample,
    display: 'Profil gauche'
  },
  {
    name: 'buccal_right',
    background: buccalRight,
    hint: buccalRightExample,
    display: 'Profil droit'
  },
  {
    name: 'labial_anterior',
    background: labialAnterior,
    hint: labialAnteriorExample,
    display: 'Occlusion antérieur'
  }
];

export const anglesWithAligners = [
  {
    name: 'buccal_left',
    background: buccalLeftOpen,
    // hint: buccalLeftExample,
    display: 'Profil gauche'
  },
  {
    name: 'buccal_left_aligners',
    background: buccalLeftOpenAligners,
    // hint: buccalLeftExample,
    display: 'Profil gauche avec aligneurs'
  },
  {
    name: 'buccal_right',
    background: buccalRightOpen,
    // hint: buccalRightExample,
    display: 'Profil droit'
  },
  {
    name: 'buccal_right_aligners',
    background: buccalRightOpenAligners,
    // hint: buccalRightExample,
    display: 'Profil droit avec aligneurs'
  },
  {
    name: 'labial_anterior',
    background: labialAnteriorOpen,
    // hint: labialAnteriorExample,
    display: 'Occlusion antérieur'
  },
  {
    name: 'labial_anterior_aligners',
    background: labialAnteriorOpenAligners,
    // hint: labialAnteriorExample,
    display: 'Occlusion antérieur avec aligneurs'
  }
];

export const end = [
  {
    name: 'labial_anterior_aligners',
    background: labialAnteriorOpenAligners,
    display: 'Occlusion antérieur avec aligneurs'
  },
  {
    name: 'labial_anterior',
    display: 'Occlusion antérieur sans aligneurs',
    displayEn: 'Labial Anterior',
    background: labialAnterior
  },
  {
    name: 'occlussal_upper',
    display: 'Arcade du haut sans aligneurs',
    displayEn: 'Occlussal Upper',
    background: occlussalUpper
  },
  {
    name: 'occlussal_lower',
    display: 'Arcade du bas sans aligneurs',
    displayEn: 'Occlussal Lower',
    background: occlussalLower
  },
  {
    name: 'buccal_left',
    display: 'Profil gauche sans aligneurs',
    displayEn: 'Buccal Left',
    background: buccalLeft
  },
  {
    name: 'buccal_right',
    display: 'Profil droit sans aligneurs',
    displayEn: 'Buccal Right',
    background: buccalRight
  }
];

export const getAnglesFromVersion = (version) => {
  if (version === VERSION_WITH_ALIGNERS) return anglesWithAligners;
  return angles;
};
