import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Consent from '../consent/Consent';
import ScriptLoader from '../../scriptLoader/ScriptLoader';
import PaymentMethods from './paymentMethods/PaymentMethods';
import Loadable from '../../Loadable';
import { v2 } from '../../../axios';
import LocalPracticianReminder from './LocalPracticianReminder';

const pledgeScriptUrl = process.env.REACT_APP_PLEDGE_SCRIPT_URL;

const Payment = ({ patient, snackbar, auth }) => {
  const { t } = useTranslation('production');
  const [isHandlingSuccess, setIsHandlingSuccess] = useState(false);
  const [hasCriticalError, setHasCriticalError] = useState(false);
  const [practicians, setPracticians] = useState([]);

  const fetchPracticians = async () => {
    try {
      const { data } = await v2.get('practicians');
      setPracticians(data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPracticians();
  }, []);

  const handleSuccess = async () => {
    setIsHandlingSuccess(true);
    snackbar.open(
      t('production:production.payment.snackbar.success'),
      'success'
    );
    await auth.fetchUser();
    setIsHandlingSuccess(false);
  };

  const handleError = () => {
    snackbar.open(t('production:production.payment.snackbar.error'), 'error');
    // dont allow user to retry, card possibly already charged by third party
    setHasCriticalError(true);
  };

  if (isHandlingSuccess)
    return (
      <Loadable
        loading
        message={t('production:production.payment.loading.message')}
      />
    );

  if (hasCriticalError || !practicians.length) return null;

  return (
    <Consent patient={patient} auth={auth} snackbar={snackbar}>
      {patient.treatment_type === 'mild' && (
        <LocalPracticianReminder practicians={practicians} />
      )}
      <ScriptLoader url="https://js.stripe.com/v3/">
        <ScriptLoader url={pledgeScriptUrl}>
          <PaymentMethods
            auth={auth}
            onSuccess={handleSuccess}
            onError={handleError}
          />
        </ScriptLoader>
      </ScriptLoader>
    </Consent>
  );
};

export default Payment;
