import styled from 'styled-components';
import { Button } from '@material-ui/core';

export const StyledButton = styled(Button)`
  ${({ theme }) => `
    padding: ${theme.spacing(2)}px ${theme.spacing(4)}px;
  `}
`;

export const StyledLabel = styled.span`
  margin-right: 8px;
`;
