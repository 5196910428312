import FileSaver from 'file-saver';
import { axiosWithoutCredentials } from '../axios';

export const isMimeTypeImage = (mimeType) =>
  ['image/gif', 'image/jpeg', 'image/png'].includes(mimeType);

export const getFileContent = async (url) => {
  const { data: content } = await axiosWithoutCredentials.get(url, {
    responseType: 'arraybuffer'
  });

  return content;
};

export const downloadPdf = (content, name) => {
  const pdf = new File([content], name, {
    type: `application/pdf`
  });

  return FileSaver.saveAs(pdf);
};
