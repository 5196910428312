/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';

import { Box, Typography, Paper, Grid } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import parseISO from 'date-fns/parseISO';
import { useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@material-ui/lab';
import DialogWithButton from '../DialogWithButton';

import Payment from './payment/Payment';
import axios from '../../axios';

import StartTreatment from './startTreatment/StartTreatment';
import TreatmentPlan3D from '../TreatmentPlan';

const styles = (theme) => ({
  statusPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const TreatmentPlan = ({ auth, snackbar, classes }) => {
  const { t } = useTranslation(['production', 'common']);

  const {
    user: { patient }
  } = auth;

  const acceptTreatment = async () => {
    try {
      await axios.get(`accept-treatment`);
      await auth.fetchUser();
      snackbar.open(
        t('production:production.treatmentPlan.accepted.snackbar.success'),
        'success'
      );
    } catch (error) {
      snackbar.open(
        t('production:production.treatmentPlan.accepted.snackbar.error'),
        'error'
      );
    }
  };

  const available = (
    <Typography color="primary">
      {t('production:production.treatmentPlan.available.message')}
    </Typography>
  );

  const decision = (
    <Paper mb={2}>
      <Box p={2}>
        <Grid container justify="center">
          <Grid item xs={12}>
            <Box pb={2}>
              <Alert severity="info">
                <Box pb={2}>
                  {patient.treatment_type === 'mild' && !patient.has_attachments
                    ? t(
                        'production:production.treatmentPlan.mildTreatmentPlanMessage'
                      )
                    : patient.treatment_type === 'mild' &&
                      patient.has_attachments &&
                      t(
                        'production:production.treatmentPlan.complexeTreatmentPlanMessage'
                      )}
                </Box>
                {t('production:production.treatmentPlan.modify')}
              </Alert>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={6}>
            <DialogWithButton
              onAccept={acceptTreatment}
              buttonProps={{
                text: t(
                  'production:production.treatmentPlan.accepted.dialog.button.text'
                ),
                fullWidth: true
              }}
              dialogProps={{
                title: t(
                  'production:production.treatmentPlan.accepted.dialog.title'
                ),
                description: t(
                  'production:production.treatmentPlan.accepted.dialog.description'
                )
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );

  const refused = (
    <Typography color="error">
      {t('production:production.treatmentPlan.refused.message')}
      {patient.decision_patient_at &&
        t('common:common.date.detailed', {
          date: parseISO(patient.decision_patient_at)
        })}
    </Typography>
  );

  const payment = (
    <Typography color="primary">
      {patient.decision_patient_at &&
        t('production:production.treatmentPlan.accepted.message', {
          date: parseISO(patient.decision_patient_at)
        })}
    </Typography>
  );

  const paid = (
    <Typography color="primary">
      {t('production:production.treatmentPlan.paid.message')}
    </Typography>
  );

  const shipped = (
    <Typography color="primary">
      {t('production:production.treatmentPlan.shipped.message')}
    </Typography>
  );

  let status;
  let content = null;

  if (
    patient.decision_practician &&
    !patient.decision_patient_at &&
    !patient.paid_at
  ) {
    status = available;
  } else if (patient.decision_patient_at && !patient.paid) {
    status = patient.decision_patient ? payment : refused;
  } else if (patient.paid && !patient.shipped_at) {
    status = paid;
  } else if (patient.shipped_at) {
    status = shipped;
  }

  if (!patient.decision_patient_at) {
    content = decision;
  } else if (patient.decision_patient && !patient.paid) {
    content = <Payment patient={patient} snackbar={snackbar} auth={auth} />;
  }

  return (
    <>
      {status && (
        <Paper className={classes.statusPaper}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="textSecondary">
                {t('production:production.treatmentPlan.title')}
              </Typography>
              {status}
            </Grid>
          </Grid>
          {!!patient.paid && (
            <Box pt={2}>
              <Alert severity="warning">
                <AlertTitle>
                  {t('production:production.treatmentPlan.alertTitle')}
                </AlertTitle>
                <strong>
                  {t('production:production.treatmentPlan.retainerWarning')}
                </strong>
              </Alert>
            </Box>
          )}
        </Paper>
      )}
      {patient.shipped_at && (
        <Paper className={classes.statusPaper}>
          <StartTreatment
            snackbar={snackbar}
            patient={patient}
            onStart={auth.fetchUser}
          />
        </Paper>
      )}
      {content}
      {patient.decision_practician_at && <TreatmentPlan3D patient={patient} />}
    </>
  );
};

export default withStyles(styles)(TreatmentPlan);
