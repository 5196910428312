import { COUNTRIES } from './vars';

export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_PENDING = 'pending';
export const STATUS_ABANDONED = 'abandoned';

export const shouldUserPayDeposit = (user) => {
  if (user.address.country === COUNTRIES.COUNTRY_FR) {
    return true;
  }

  return false;
};
