import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { parseISO } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';

const OfficeSession = ({ patient }) => {
  const { t } = useTranslation(['officeSessions', 'common']);
  const { actual_aligners } = patient;
  const bookableSession = patient.office_sessions.find(
    (officeSession) =>
      officeSession.status === 'incoming' &&
      officeSession.serie === actual_aligners.serie + 1
  );

  if (!bookableSession) {
    return null;
  }

  const { booking } = patient;
  const isBooked = booking.status === 'confirmed';

  return (
    <Grid item xs={12}>
      <Box p={2}>
        {isBooked ? (
          <Typography color="primary" align="center">
            <Trans
              t={t}
              i18nKey="officeSessions:officeSessions.isBooked.message"
            >
              <span>Prochain rendez-vous le </span>
              <strong>
                {{
                  bookingDate: t('common:common.date.detailed', {
                    date: parseISO(booking.date)
                  })
                }}
              </strong>
              <span>avant le passage aux </span>
              <strong>
                aligneurs
                {{ actualAligners: actual_aligners.serie + 1 }}
              </strong>
            </Trans>
          </Typography>
        ) : (
          <Typography color="primary" align="center">
            {t('officeSessions:officeSessions.notBooked.message')}{' '}
            {bookableSession.serie}
          </Typography>
        )}
        {!isBooked && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="outlined"
              size="small"
              component={Link}
              to={`/book?session=${bookableSession.serie}`}
              color="primary"
            >
              {t('officeSessions:officeSessions.notBooked.button.text')}
            </Button>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default OfficeSession;
