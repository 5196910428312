/* eslint-disable no-console */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Paper, Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  STRIPE_METHOD,
  getCommonPledgSettings,
  attemptPaymentWithPledgECard
} from '../../../../utils/pledg.utils';
import SelectPaymentMethod from './SelectPaymentMethod';
import Stripe from './stripe/Stripe';
import PaymentDetails from '../paymentDetails/PaymentDetails';
import { StyledPledgContainer } from './PaymentMethods.styled';
import usePledg from '../../../../hooks/usePledg';
import { getUrlParam } from '../../../../utils/router.utils';
import AcceptCGV from '../AcceptCGV';
import Loadable from '../../../Loadable';

const PaymentMethods = ({ auth, onSuccess, onError }) => {
  const { t } = useTranslation('production');

  const activePaymentMethod = getUrlParam('plan') ?? STRIPE_METHOD;
  const { instance, initialize, hasError, paymentMethods } = usePledg();
  const buttonRef = useRef(null);
  const [isAttemptingPayment, setIsAttemptingPayment] = useState(false);
  const containerElement = useRef(null);
  const [acceptedCgv, setAcceptedCgv] = useState(false);

  const handlePledgPayment = useCallback(
    async (data) => {
      try {
        setIsAttemptingPayment(true);
        await attemptPaymentWithPledgECard(data);
        await onSuccess();
      } catch (error) {
        onError();
      } finally {
        setIsAttemptingPayment(false);
      }
    },
    [onSuccess, onError]
  );

  const handleError = (error) => {
    console.error(error);
  };

  const getPledgOptions = useCallback(
    (merchantUid) => ({
      merchantUid,
      containerElement: containerElement.current,
      ...getCommonPledgSettings(auth.user),
      onSuccess: handlePledgPayment,
      onError: handleError
    }),
    [auth.user, handlePledgPayment]
  );

  useEffect(() => {
    if (instance || !buttonRef?.current || !paymentMethods) return;
    const plan = paymentMethods.find((pm) => pm.name === activePaymentMethod);
    if (!plan) return;
    initialize(buttonRef.current, getPledgOptions(plan.id));
    buttonRef.current.click();
  }, [
    getPledgOptions,
    instance,
    buttonRef,
    initialize,
    activePaymentMethod,
    paymentMethods
  ]);

  const handleSelect = (planName) => {
    if (planName === activePaymentMethod) return;

    if (planName === STRIPE_METHOD) {
      window.location.href = '/';
      return;
    }
    window.location.href = `/?plan=${planName}`;
  };

  const handleCouponApply = async () => {
    window.location.reload(true);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" gutterBottom>
              {t('production:production.payment.methods.title')}
            </Typography>

            <Loadable loading={!paymentMethods}>
              <SelectPaymentMethod
                buttonRef={buttonRef}
                disabled={isAttemptingPayment}
                onSelect={handleSelect}
                activePaymentMethod={activePaymentMethod}
                paymentMethods={paymentMethods}
              />
              <AcceptCGV
                onChange={() => {
                  setAcceptedCgv((a) => !a);
                }}
                accepted={acceptedCgv}
              />
            </Loadable>

            {hasError && !isAttemptingPayment ? (
              <Box mt={2}>
                <Typography color="error" align="center">
                  {t('production:production.payment.methods.error.message')}
                </Typography>
              </Box>
            ) : (
              acceptedCgv && (
                <PaymentDetails
                  activePaymentMethod={activePaymentMethod}
                  paymentMethods={paymentMethods}
                  isAttemptingPayment={isAttemptingPayment}
                  treatmentType={auth.user.patient.treatment_type}
                  price={auth.user.patient.price}
                  onCouponApply={handleCouponApply}
                />
              )
            )}
            {activePaymentMethod === STRIPE_METHOD && acceptedCgv && (
              <Stripe auth={auth} />
            )}
          </Box>
        </Paper>
      </Grid>
      <StyledPledgContainer
        item
        xs={12}
        hidden={activePaymentMethod === STRIPE_METHOD || !acceptedCgv}
      >
        <Box ref={containerElement} />
      </StyledPledgContainer>
    </Grid>
  );
};

export default PaymentMethods;
