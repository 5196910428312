import React, { Component, Fragment } from 'react';

import ReactPlayer from 'react-player';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';

import { withStyles } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next';
import Loadable from './Loadable';
import axios from '../axios';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  },
  sliderContainer: {
    maxWidth: '800px',
    width: '100%',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  playerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  stepsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  }
});

const angles = [
  'all',
  'lateral_right',
  'facial_frontal',
  'lateral_left',
  'upper_occlusal',
  'over_jet',
  'lower_occlusal'
];

class TreatmentPlan extends Component {
  state = {
    all: null,
    lateral_right: null,
    facial_frontal: null,
    lateral_left: null,
    upper_occlusal: null,
    over_jet: null,
    lower_occlusal: null,
    isFetching: false,
    fetched: false,
    active: 'all',
    played: 0,
    seeking: false,
    playing: false
  };

  componentDidMount = async () => {
    await this.fetchTreatmentPlan();
  };

  componentWillUnmount = () => {
    const { fetched } = this.state;

    if (!fetched) {
      return;
    }

    [
      'all',
      'lateral_right',
      'facial_frontal',
      'lateral_left',
      'upper_occlusal',
      'over_jet',
      'lower_occlusal'
    ].forEach((angle) => {
      const { [angle]: angleValue } = this.state;

      if (!angleValue) {
        return;
      }

      URL.revokeObjectURL(angleValue.url);
    });
  };

  handleChangeAngle = (angle) => {
    const { played } = this.state;
    this.setState({ active: angle });
    this.player.seekTo(played);
  };

  onSeekMouseDown = () => {
    this.setState({ seeking: true });
  };

  onSeekMouseUp = () => {
    this.setState({ seeking: false });
  };

  onSeekChange = (e, value) => {
    this.setState({ played: value });
    this.player.seekTo(value);
  };

  // eslint-disable-next-line class-methods-use-this
  onReady = () => {};

  onProgress = (state) => {
    const { seeking } = this.state;
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      this.setState({ played: state.played });
    }
  };

  fetchTreatmentPlan = async () => {
    const { treatmentPlan } = this.props;
    this.setState({ isFetching: true });

    try {
      await treatmentPlan.files.forEach((video) => {
        this.setState({
          [video.meta.angle]: video
        });
      });

      await this.fetchVideos(treatmentPlan.files);

      this.setState({ isFetching: false, fetched: true });
    } catch (error) {
      this.setState({
        isFetching: false,
        error
      });
    }
  };

  fetchVideos = async (videos) => {
    await Promise.all(videos.map((video) => this.fetchVideo(video.meta.angle)));
  };

  fetchVideo = async (angle) => {
    const { [angle]: angleValue } = this.state;

    const file = angleValue;

    const { data: content } = await axios.get(`download-file/${file.id}`, {
      responseType: 'arraybuffer'
    });

    const blob = new Blob([content], { type: file.mime_type });

    this.setState({
      [file.meta.angle]: { ...file, url: URL.createObjectURL(blob) }
    });
  };

  ref = (player) => {
    this.player = player;
  };

  render() {
    const { classes, treatmentPlan, t } = this.props;
    const { isFetching, fetched, active, played, playing, error, all } =
      this.state;
    const { [active]: activeValue } = this.state;

    const player = fetched && (
      <>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <div className={classes.playerContainer}>
            <ReactPlayer
              url={activeValue.url}
              ref={this.ref}
              onProgress={this.onProgress}
              playing={playing}
              onReady={this.onReady}
              width="100%"
              height="auto"
            />

            <div className={classes.sliderContainer}>
              <div className={classes.stepsContainer}>
                <Typography component="span">0</Typography>
                {[...Array(treatmentPlan.nb_aligners).keys()].map((step) => (
                  <Typography component="span" key={step}>
                    {(step + 1) * 2}
                  </Typography>
                ))}
              </div>
              <Slider
                min={0}
                max={0.9}
                value={played}
                aria-labelledby="slider-label"
                onChange={this.onSeekChange}
                onDragStart={this.onSeekMouseDown}
                onDragEnd={this.onSeekMouseUp}
                step={0.05}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} />
      </>
    );

    const thumbnails = fetched && (
      <>
        <Grid item xs={8} sm={6} md={4}>
          <ReactPlayer
            url={all.url}
            onProgress={this.onProgress}
            playing={playing}
            onReady={this.onReady}
            width="100%"
            height="auto"
            onClick={() => {
              this.setState({ active: 'all' });
            }}
          />
        </Grid>
        <Grid item xs={12} />
        {angles.map((angle) => {
          const { [angle]: angleValue } = this.state;

          if (angle === 'all' || !angleValue) {
            return null;
          }
          return (
            <Grid item xs={4} md={2} key={angle}>
              <ReactPlayer
                url={angleValue.url}
                onProgress={this.onProgress}
                playing={playing}
                onReady={this.onReady}
                width="100%"
                height="auto"
                onClick={() => {
                  this.setState({ active: angle });
                }}
              />
            </Grid>
          );
        })}
      </>
    );

    return (
      <div className={classes.root}>
        <Loadable
          loading={isFetching}
          message="Plan de traitement en cours de préparation"
        >
          <Grid container justifyContent="center" spacing={1}>
            {error && (
              <Typography color="error">
                {t('treatmentPlan:treatmentPlan.staticRenderer.error')}
              </Typography>
            )}

            {player}
            {thumbnails}
          </Grid>
        </Loadable>
      </div>
    );
  }
}
const TreatmentPlanWithStyles = withStyles(styles)(TreatmentPlan);
export default withTranslation('treatmentPlan')(TreatmentPlanWithStyles);
