import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Questions from './Questions';
import Status from './Status';

import PhotosUpload from '../../PhotosUpload';
import Upload from '../../Upload';
import DialogWithButton from '../../DialogWithButton';
import axios from '../../../axios';
import RedirectToAuth from '../../RedirectToAuth';

const MedicalData = ({ auth, snackbar }) => {
  const { t } = useTranslation('production');
  const { patient } = auth.user;
  const [hasXray, setHasXray] = useState(false);

  const needsToCompleteProfile =
    auth.user.gender === 'u' && auth.user.patient.status !== 'treatment';
  if (needsToCompleteProfile) {
    return <RedirectToAuth to="/profile/personal-data/edit" />;
  }

  const handleConfirm = async () => {
    try {
      await axios.get('/accept-transfer-data');
      snackbar.open(
        t('production:production.medicalData.snackbar.success'),
        'success'
      );
      await auth.fetchUser();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Status patient={auth.user.patient} hasXray={hasXray} />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {!patient.health_questions ? (
            <Grid item xs={12}>
              <Questions auth={auth} snackbar={snackbar} />
            </Grid>
          ) : (
            <>
              {!patient.accepted_allow_transfer_data_at && (
                <>
                  <Grid item xs={12} sm={12} md={8} />
                  <Grid item xs={12} sm={12} md={4}>
                    <DialogWithButton
                      onAccept={handleConfirm}
                      buttonProps={{
                        size: 'small',
                        color: 'primary',
                        variant: 'contained',
                        text: t(
                          'production:production.medicalData.dialog.button.text'
                        ),
                        fullWidth: true,
                        disabled: patient.requested_xray && !hasXray
                      }}
                      dialogProps={{
                        title: t(
                          'production:production.medicalData.dialog.title'
                        ),
                        description: t(
                          'production:production.medicalData.dialog.description'
                        )
                      }}
                      confirmButtonProps={{
                        text: t(
                          'production:production.medicalData.dialog.confirmButton.text'
                        ),
                        variant: 'contained'
                      }}
                    />
                  </Grid>
                </>
              )}
              {/* XRAY */}
              <Grid item xs={12}>
                <Upload
                  description={t(
                    'production:production.medicalData.xray.description'
                  )}
                  receiverId={patient.id}
                  receiverType="patient"
                  type="treatment_xray"
                  fileName={`radiographie-${patient.reference}`}
                  retryable={patient.requested_xray}
                  autoUpload
                  dropzoneLabel={t(
                    'production:production.medicalData.xray.dropzoneLabel'
                  )}
                  onSuccess={() => {
                    setHasXray(true);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PhotosUpload
                  patient={patient}
                  serie="check_up"
                  auth={auth}
                  snackbar={snackbar}
                  readOnly
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MedicalData;
