import React, { useCallback, useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  BOOKING_END_PRICE,
  formatNumberToCurrency
} from '../../../../utils/payment';
import { computeFee, STRIPE_METHOD } from '../../../../utils/pledg.utils';
import Coupon from '../../../coupons/Coupon';
import Loadable from '../../../Loadable';

const PaymentDetails = ({
  activePaymentMethod,
  treatmentType,
  price,
  onCouponApply,
  isAttemptingPayment,
  paymentMethods
}) => {
  const { t } = useTranslation('production');
  const [coupon, setCoupon] = useState(null);
  const activePlan = paymentMethods.find(
    (pm) => pm.name === activePaymentMethod
  );
  const fee =
    activePaymentMethod === STRIPE_METHOD ? 0 : computeFee(activePlan, price);
  const isSimpleTreatment = treatmentType === 'simple';
  const couponAmount = coupon ? coupon.amount / 100 : 0;
  const toDeduct = isSimpleTreatment ? BOOKING_END_PRICE : 0;
  return (
    <Box mt={2} mb={2}>
      <Loadable
        loading={isAttemptingPayment}
        message={t('production:production.payment.details.message')}
      >
        <Grid item xs={12}>
          <Coupon
            onApply={useCallback(
              async (appliedCoupon) => {
                setCoupon(appliedCoupon);
                await onCouponApply();
              },
              [onCouponApply]
            )}
            onRemove={() => {
              setCoupon(null);
              onCouponApply();
            }}
            onFetch={useCallback((fetchedCoupon) => {
              setCoupon(fetchedCoupon);
            }, [])}
          />
        </Grid>
        <Typography variant="h6" gutterBottom>
          {t('production:production.payment.details.title')}
        </Typography>

        <Grid container spacing={2}>
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography>
                {t('production:production.payment.details.priceLabel')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" color="textSecondary">
                {formatNumberToCurrency(
                  price + parseInt(couponAmount, 10) + toDeduct
                )}
              </Typography>
            </Grid>
          </Grid>
          {activePlan && (
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>
                  {t('production:production.payment.details.activePlan.title')}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {t(
                    'production:production.payment.details.activePlan.content'
                  )}{' '}
                  {t(
                    `production:production.payment.methods.${activePlan.name}`
                  ).toLowerCase()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right" color="textSecondary">
                  {formatNumberToCurrency(fee)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {coupon && (
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>
                  {t('production:production.payment.details.coupon.title')}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {t('production:production.payment.details.coupon.content')} :{' '}
                  {coupon.code}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right" color="textSecondary">
                  - {formatNumberToCurrency(coupon.amount / 100)}
                </Typography>
              </Grid>
            </Grid>
          )}
          {isSimpleTreatment && (
            <Grid container item xs={12}>
              <Grid item xs={6}>
                <Typography>
                  {t(
                    'production:production.payment.details.isSimpleTreatment.title'
                  )}
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  {t(
                    'production:production.payment.details.isSimpleTreatment.content'
                  )}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right" color="textSecondary">
                  - {formatNumberToCurrency(BOOKING_END_PRICE)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12}>
            <Grid item xs={6}>
              <Typography variant="h6">
                {t('production:production.payment.details.total')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="right" variant="h6">
                {formatNumberToCurrency(price + fee)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Loadable>
    </Box>
  );
};

export default PaymentDetails;
