import React from 'react';

import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import parseISO from 'date-fns/parseISO';
import { withTranslation } from 'react-i18next';

function Aligner({ aligner, patient, isRemaining, t }) {
  const startDate = isRemaining ? aligner.start : aligner.started_at;

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h6"
          component="h2"
          color={isRemaining ? 'textSecondary' : 'initial'}
        >
          {`${aligner.serie} / ${patient.nb_aligners}`}
        </Typography>
        <Typography color="textSecondary">
          {t('common:common.date.detailed', {
            date: parseISO(startDate)
          })}{' '}
          {!isRemaining &&
            aligner.finished_at &&
            `- ${t('common:common.date.detailed', {
              date: parseISO(aligner.finished_at)
            })}`}
          {!isRemaining &&
            ` (${aligner.days_worn} ${
              aligner.days_worn > 1
                ? t('treatments:treatments.aligner.days')
                : t('treatments:treatments.aligner.day')
            })`}
        </Typography>
      </CardContent>
      <CardActions>
        {!isRemaining && (
          <>
            {(aligner.finished_at || aligner.can_be_changed) && (
              <Button
                size="small"
                component={Link}
                to={`/traitement/${aligner.serie}`}
                color="primary"
              >
                {aligner.finished_at
                  ? t('treatments:treatments.aligner.finished.button.text')
                  : t('treatments:treatments.aligner.notFinished.button.text')}
              </Button>
            )}
            <Button
              size="small"
              component={Link}
              to={`/traitement/${aligner.serie}/edit`}
              color="primary"
            >
              {t('treatments:treatments.aligner.edit.button.text')}
            </Button>
          </>
        )}
      </CardActions>
    </Card>
  );
}

export default withTranslation(['treatments', 'common'])(Aligner);
