import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from 'react-i18next';
import Loadable from '../Loadable';
import Invoice from './Invoice';
import axios from '../../axios';
import TreatmentInvoice from './TreatmentInvoice';
import Quotation from './Quotation';
import Coupon from '../coupons/Coupon';

const Main = ({ auth }) => {
  const { t } = useTranslation('invoices');
  const [invoices, setInvoices] = useState([]);
  const [quotation, setQuotation] = useState(null);
  const [fetched, setFetched] = useState({});
  const [localInvoice, setLocalInvoice] = useState(null);

  useEffect(() => {
    const fetchInvoices = async () => {
      const { data: fetchedInvoices } = await axios.get('invoices');
      const filteredInvoices = fetchedInvoices.filter(
        (i) => i.service === 'treatment' && i.type === 'prescriptor'
      );
      setInvoices(filteredInvoices);
      const local = fetchedInvoices.find((i) => i.type === 'local');
      setLocalInvoice(local);

      setFetched((current) => ({ ...current, invoices: true }));
    };

    const fetchQuotation = async () => {
      const { data: canDownload } = await axios.get(`quotation`);
      setQuotation(canDownload);
      setFetched((current) => ({ ...current, quotation: true }));
    };

    fetchInvoices();
    fetchQuotation();
  }, []);

  return (
    <Loadable loading={!fetched.invoices || !fetched.quotation}>
      {!auth.user.patient.paid && !auth.user.patient.paid_at && <Coupon />}
      {fetched.invoices &&
      fetched.quotation &&
      !quotation &&
      !invoices.length ? (
        <Grid item xs={12}>
          <Typography>
            {t('invoices:invoices.main.noInvoices.message')}
          </Typography>
        </Grid>
      ) : (
        <Grid container spacing={2}>
          {!!invoices.length && (
            <>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t('invoices:invoices.main.title')}
                </Typography>
              </Grid>
              {invoices.map((invoice) =>
                invoice.service === 'treatment' ? (
                  <TreatmentInvoice
                    key={invoice.id}
                    invoice={invoice}
                    patient={auth.user.patient}
                    localInvoice={localInvoice}
                  />
                ) : (
                  <Invoice
                    key={invoice.id}
                    invoice={invoice}
                    patient={auth.user.patient}
                  />
                )
              )}
            </>
          )}
          <Grid item xs={12} />
          {!!quotation && <Quotation patient={auth.user.patient} />}
        </Grid>
      )}
    </Loadable>
  );
};

export default Main;
