import React from 'react';

import { DatePicker } from '@material-ui/pickers';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing()
  }
});

const MyDatePicker = ({
  input: { name, onChange, value, onBlur, onFocus, ...restInput },
  meta,
  classes,
  ...rest
}) => {
  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched;

  const handleChange = (event) => {
    onChange(event);
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  return (
    <DatePicker
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      name={name}
      value={value}
      error={showError}
      helperText={showError ? meta.error || meta.submitError : undefined}
      inputProps={restInput}
      onChange={handleChange}
      onBlur={onBlur}
      className={classes.formControl}
    />
  );
};

export default withStyles(styles)(MyDatePicker);
