import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import BookingRequest from './BookingRequest';

const BookingRequestConfirmed = ({ bookingRequest }) => {
  const { t } = useTranslation('bookingRequests');
  return (
    <Box>
      <Box p={2} display="flex" justifyContent="center">
        <Typography align="center" variant="h4" gutterBottom>
          {t('bookingRequests:bookingRequests.alreadyConfirmed')}
        </Typography>
      </Box>

      <Box mt={2} display="flex" justifyContent="center">
        <BookingRequest bookingRequest={bookingRequest} />
      </Box>
    </Box>
  );
};

export default BookingRequestConfirmed;
