import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
import Aligner from './Aligner';
import axios from '../../axios';
import TreatmentPlan from '../TreatmentPlan';

class Aligners extends Component {
  state = {
    remainingAligners: null
  };

  async componentDidMount() {
    const { auth } = this.props;

    await auth.fetchUser();
    await this.fetchRemainingAligners();
  }

  fetchRemainingAligners = async () => {
    const { data: remainingAligners } = await axios.get(`remaining-aligners`);

    this.setState({ remainingAligners });
  };

  render() {
    const { remainingAligners } = this.state;
    const { patient } = this.props;
    const { aligners } = patient;

    return (
      <Grid container spacing={2}>
        {aligners &&
          aligners.map((aligner) => {
            return (
              <Grid key={aligner.serie} item xs={12} sm={4}>
                <Aligner aligner={aligner} patient={patient} />
              </Grid>
            );
          })}

        {remainingAligners &&
          remainingAligners.map((aligner) => {
            return (
              <Grid key={aligner.serie} item xs={12} sm={4}>
                <Aligner aligner={aligner} patient={patient} isRemaining />
              </Grid>
            );
          })}
        <Grid item xs={12}>
          <TreatmentPlan patient={patient} />
        </Grid>
      </Grid>
    );
  }
}

export default Aligners;
