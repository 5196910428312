import { Typography, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useTranslation } from 'react-i18next';

const LocalPracticianReminder = ({ practicians }) => {
  const { t } = useTranslation('production');
  const localPractician = practicians.find((p) => p.pivot.type === 'local');

  return (
    <Box pb={2} pt={2}>
      {localPractician && (
        <>
          <Alert severity="warning">
            <Typography gutterBottom>
              {t('production:production.payment.localPractitionerInformations')}
            </Typography>
            <Typography variant="subtitle2">
              {localPractician.fullname}
            </Typography>
            <Typography variant="subtitle2">{localPractician.alias}</Typography>
            <Typography variant="subtitle2">
              {localPractician.address.address}
            </Typography>
            <Typography gutterBottom variant="subtitle2">
              {localPractician.address.postal_code}{' '}
              {localPractician.address.city}
            </Typography>
            <Typography>
              {t('production:production.payment.changeLocalPractitioner')}
            </Typography>
          </Alert>
        </>
      )}
    </Box>
  );
};

export default LocalPracticianReminder;
