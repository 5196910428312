import React, { Component, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';

import PlacesAutocomplete, {
  geocodeByPlaceId,
  getLatLng
} from 'react-places-autocomplete';
import { withTranslation } from 'react-i18next';
import Coupon from '../coupons/Coupon';

const searchOptions = {
  types: ['geocode'],
  componentRestrictions: { country: ['fr', 'be', 'es'] }
};

class Search extends Component {
  state = {
    error: null,
    value: '',
    fetching: false
  };

  async componentDidMount() {
    await this.fetchPosition();
  }

  handleSelect = async (address, placeId) => {
    const { onSelect } = this.props;

    const response = await geocodeByPlaceId(placeId);
    const coordinates = await getLatLng(response[0]);

    this.setState({ value: address });

    this.setState({ fetching: true });
    if (onSelect) {
      await onSelect(coordinates);
    }
    this.setState({ fetching: false });
  };

  handleError = (error) => {
    this.setState({ error });
  };

  handleChange = (value) => {
    this.setState({ value });
    this.setState({ error: null });
  };

  // eslint-disable-next-line class-methods-use-this
  getCurrentPosition = (options = {}) => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
  };

  fetchPosition = async () => {
    try {
      const { onSelect } = this.props;
      const { coords } = await this.getCurrentPosition();
      const { latitude, longitude } = coords;

      this.setState({ fetching: true });

      if (onSelect) {
        await onSelect({
          lat: latitude,
          lng: longitude
        });
      }

      this.setState({ fetching: false });
    } catch (error) {
      // Handle error
    }
  };

  render() {
    const { t } = this.props;
    const { value, error, fetching } = this.state;

    return (
      <PlacesAutocomplete
        value={value}
        searchOptions={searchOptions}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
      >
        {function renderAutoComplete({
          getInputProps,
          suggestions,
          getSuggestionItemProps,
          loading
        }) {
          return (
            <>
              <Typography align="center" variant="h6">
                {t('bookings:bookings.searchAddress.label')}
              </Typography>
              <br />
              <Coupon />
              <br />
              <TextField
                name="address"
                helperText={error ? error.message : undefined}
                placeholder={t('bookings:bookings.searchAddress.placeholder')}
                error={!!error}
                onChange={this.handleChange}
                value={value}
                fullWidth
                variant="outlined"
                disabled={fetching}
                autoFocus
                InputProps={{
                  endAdornment:
                    loading || fetching ? (
                      <InputAdornment position="end">
                        <CircularProgress size={20} />
                      </InputAdornment>
                    ) : (
                      <InputAdornment position="end">
                        <GpsFixedIcon color="primary" />
                      </InputAdornment>
                    ),
                  disabled: fetching
                }}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getInputProps()}
              />

              <Paper square>
                {error ? (
                  <MenuItem component="div" disabled>
                    {t('bookings:bookings.searchAddress.no_results')}
                  </MenuItem>
                ) : (
                  suggestions
                    .filter((suggestion) => {
                      return (
                        suggestion.types.includes('street_address') ||
                        suggestion.types.includes('geocode')
                      );
                    })
                    .map((suggestion) => (
                      <MenuItem
                        key={suggestion.id}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...getSuggestionItemProps(suggestion)}
                        component="div"
                      >
                        {suggestion.description}
                      </MenuItem>
                    ))
                )}
              </Paper>
            </>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export default withTranslation('bookings')(Search);
