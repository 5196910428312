import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Practician from './Practician';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flex: '1 1'
    }
  }
});

function Practicians({
  classes,
  practicians,
  toConfirm,
  setActivePractician,
  snackbar,
  onBook,
  auth,
  createCheckoutSession,
  activePractician
}) {
  return (
    <div className={classes.root}>
      {practicians.map((practician, index) => (
        <Practician
          key={practician.id}
          index={index}
          practician={practician}
          toConfirm={toConfirm}
          setActivePractician={setActivePractician}
          snackbar={snackbar}
          onBook={onBook}
          auth={auth}
          createCheckoutSession={createCheckoutSession}
          activePractician={activePractician}
        />
      ))}
    </div>
  );
}

export default withStyles(styles)(Practicians);
