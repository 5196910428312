import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import { withTranslation } from 'react-i18next';
import axios from '../../../axios';
import ScriptLoader from '../../scriptLoader/ScriptLoader';

class Consent extends Component {
  state = {
    document: null
  };

  fetchDocument = async () => {
    const { data: document } = await axios.get('/consent-document');

    this.setState({ document });

    await this.loadDocument();
  };

  loadDocument = async () => {
    const { document } = this.state;

    const url = document.signers[0].embedded_signing_url;

    const { fetchDocument } = this;
    const { auth, snackbar, t } = this.props;

    if (window.eversign) {
      window.eversign.open({
        url,
        width: '100%',
        height: 800,
        containerID: 'container',
        events: {
          loaded() {},
          async signed() {
            await fetchDocument();
            await auth.fetchUser();

            snackbar.open(t('production:production.consent.snackbar.success'));
          },
          declined() {},
          error() {}
        }
      });
    }
  };

  render() {
    const { children, auth } = this.props;

    if (auth.user.patient.accepted_consent_at) {
      return children;
    }

    return (
      <>
        <ScriptLoader
          url="https://static.eversign.com/js/embedded-signing.js"
          onLoad={this.fetchDocument}
        />
        <Grid container>
          <Grid item xs={12}>
            <div id="container" />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withTranslation('production')(Consent);
