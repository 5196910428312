export const TYPE_PRE_TREATMENT = 'pre_treatment';
export const TYPE_MID_TREATMENT = 'mid_treatment';
export const TYPE_END_TREATMENT = 'end_treatment';
export const TYPE_URGENT = 'urgent';
export const TYPE_OTHER = 'other';

export const STATUS_PENDING = 'pending';
export const STATUS_CONFIRMED = 'confirmed';
export const STATUS_CANCELED = 'canceled';
export const STATUS_NOT_HONORED = 'not_honored';
export const STATUS_DONE = 'done';

export const types = [
  { name: TYPE_PRE_TREATMENT, display: "Examen et prise d'empreintes" },
  { name: TYPE_MID_TREATMENT, display: 'Mi-traitement' },
  { name: TYPE_END_TREATMENT, display: 'Fin de traitement' },
  { name: TYPE_URGENT, display: 'Urgence' },
  { name: TYPE_OTHER, display: 'Autre' }
];

export const statuses = [
  { name: STATUS_PENDING, display: 'En attente' },
  { name: STATUS_CONFIRMED, display: 'Confirmé' },
  { name: STATUS_CANCELED, display: 'Annulé' },
  { name: STATUS_NOT_HONORED, display: 'Non Honoré' },
  { name: STATUS_DONE, display: 'Terminé' }
];
