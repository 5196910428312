import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import AuthContext, { AuthProvider } from './auth/AuthContext';
import SnackbarContext, { SnackbarProvider } from './SnackbarContext';
import Authenticated from './Authenticated';

const Layout = () => (
  <SnackbarProvider>
    <Router>
      <SnackbarContext.Consumer>
        {(snackbar) => (
          <AuthProvider snackbar={snackbar}>
            <AuthContext.Consumer>
              {(auth) => <Authenticated auth={auth} />}
            </AuthContext.Consumer>
          </AuthProvider>
        )}
      </SnackbarContext.Consumer>
    </Router>
  </SnackbarProvider>
);

export default Layout;
