import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paperStatus: {
    padding: theme.spacing(2)
  },
  completed: {
    color: 'green'
  }
}));

const Status = ({ patient, hasXray }) => {
  const { t } = useTranslation('production');
  const classes = useStyles();
  return (
    <Paper className={classes.paperStatus}>
      <Typography variant="h5" color="primary" gutterBottom>
        {t('production:production.medicalData.status.title')}
      </Typography>
      {!patient.accepted_allow_transfer_data_at ? (
        <>
          <Typography>
            {t(
              'production:production.medicalData.status.medicalDataNotTransferred.message.title'
            )}
          </Typography>
          <ul>
            <Typography
              component="li"
              className={patient.health_questions && classes.completed}
            >
              {t(
                'production:production.medicalData.status.medicalDataNotTransferred.message.healthQuestionsCompleted'
              )}
            </Typography>
            {!!patient.requested_xray && (
              <Typography
                component="li"
                className={hasXray && classes.completed}
              >
                {t(
                  'production:production.medicalData.status.medicalDataNotTransferred.message.hasXray'
                )}
              </Typography>
            )}
            <Typography
              component="li"
              className={
                patient.accepted_allow_transfer_data_at && classes.completed
              }
            >
              {t(
                'production:production.medicalData.status.medicalDataNotTransferred.message.acceptedAllowTransferDataAt'
              )}
            </Typography>
          </ul>
        </>
      ) : (
        <Typography>
          {t(
            'production:production.medicalData.status.medicalDataAlreadyTransferred.message'
          )}
        </Typography>
      )}
    </Paper>
  );
};

export default Status;
