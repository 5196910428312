import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import parseISO from 'date-fns/parseISO';
import { useTranslation } from 'react-i18next';

import { shouldUserPayDeposit } from '../../../utils/bookingRequests.utils';
import DialogWithButton from '../../DialogWithButton';

const DialogConfirm = ({
  setShowConfirm,
  handleSubmit,
  practician,
  hasValidationErrors,
  patient,
  values
}) => {
  const { t } = useTranslation(['dialogConfirm', 'common']);

  return (
    <>
      <Grid item xs={6} sm={6} md={4}>
        <Button
          onClick={() => {
            setShowConfirm(false);
          }}
          color="secondary"
          size="small"
          autoFocus
          fullWidth
        >
          {t('dialogConfirm:dialogConfirm.cancel')}
        </Button>
      </Grid>
      <Grid item xs={6} sm={6} md={4}>
        <DialogWithButton
          onAccept={handleSubmit}
          buttonProps={{
            size: 'small',
            color: 'primary',
            variant: 'contained',
            text: t('dialogConfirm:dialogConfirm.proceed'),
            disabled: hasValidationErrors,
            fullWidth: true
          }}
          dialogProps={{
            title: t('dialogConfirm:dialogConfirm.title'),
            description:
              !patient.booking_deposit_paid_at &&
              shouldUserPayDeposit(patient.user) ? (
                <>
                  <Typography gutterBottom>
                    {t(
                      'dialogConfirm:dialogConfirm.bookingDepositNotPaid.reason'
                    )}
                  </Typography>
                  <Typography gutterBottom>
                    {t(
                      'dialogConfirm:dialogConfirm.bookingDepositNotPaid.refund'
                    )}
                  </Typography>
                </>
              ) : (
                <span>
                  {practician.availabilities_source !== 'doctolib' &&
                    t(
                      'dialogConfirm:dialogConfirm.bookingDepositPaid.availabilitiesSourceIsNotDoctolib.confirm',
                      {
                        date: `${t('common:common.date.detailed', {
                          date: parseISO(values.date)
                        })} - ${t('common:common.date.time', {
                          date: values.hour
                        })}`
                      }
                    )}
                  <br />
                  <br />
                  {t(
                    'dialogConfirm:dialogConfirm.bookingDepositPaid.generalDescription'
                  )}
                  <br />
                  <br />{' '}
                  {practician.availabilities_source === 'doctolib' &&
                    t(
                      'dialogConfirm:dialogConfirm.bookingDepositPaid.availabilitiesSourceIsDoctolib.redirectToDoctolib'
                    )}
                </span>
              )
          }}
          confirmButtonProps={{
            text: t('dialogConfirm:dialogConfirm.proceed'),
            variant: 'contained'
          }}
        />
      </Grid>
    </>
  );
};

export default DialogConfirm;
