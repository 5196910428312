/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Trans, withTranslation } from 'react-i18next';
import PhotosUpload from '../PhotosUpload';

import AdditionalReview from '../treatment/AdditionalReview';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

function ChangeAligner({ patient, auth, snackbar, classes, t }) {
  const { actual_aligners: aligner } = patient;

  // check if pending additional review
  const additionalReview = aligner.additional_reviews.find(
    (a) => !a.reviewed_at
  );

  if (additionalReview)
    return (
      <AdditionalReview
        patient={patient}
        aligner={aligner}
        additionalReview={additionalReview}
        onReview={auth.fetchUser}
      />
    );

  return (
    <div>
      <Paper className={classes.root}>
        {aligner.is_pending_review ? (
          <>
            <Typography gutterBottom variant="h5">
              {t('dashboard:dashboard.changeAligner.pendingReview.title')}
            </Typography>
            <Typography>
              <Trans
                t={t}
                i18nKey="dashboard:dashboard.changeAligner.pendingReview.content"
              >
                Nous avons bien recu vos photos pour les
                <strong>
                  aligneurs numéro
                  {{ alignerNumber: aligner.serie }}
                </strong>
                , ils sont en cours d'évaluation. Il est important que vous
                continuiez à les porter, vous serez notifié par email et SMS
                pour passer aux prochains aligneurs.
              </Trans>
            </Typography>
          </>
        ) : (
          <>
            <Typography gutterBottom variant="h5">
              {t('dashboard:dashboard.changeAligner.canRequestReview.title')}
            </Typography>
            <Typography>
              <Trans
                t={t}
                i18nKey="dashboard:dashboard.changeAligner.canRequestReview.content"
              >
                Vous portez les
                <strong>
                  aligneurs numéro
                  {{ alignerNumber: aligner.serie }}
                </strong>
                , il est temps de télécharger vos photos pour évaluation par
                votre orthodontiste.
              </Trans>
            </Typography>
          </>
        )}
      </Paper>
      <PhotosUpload
        patient={patient}
        serie={aligner.serie}
        auth={auth}
        snackbar={snackbar}
        allowNoPhotos
        aligner={aligner}
        readOnly={aligner.is_pending_review}
      />
    </div>
  );
}
const ChangeAlignerWithStyles = withStyles(styles)(ChangeAligner);
export default withTranslation('dashboard')(ChangeAlignerWithStyles);
