import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { end } from '../../../constants/photos.constant';
import Upload from '../../Upload';

const EndTreatmentPhotos = ({ patient }) => {
  const { t } = useTranslation('treatments');
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {t('treatments:treatments.endTreatment.title')}
        </Typography>
        <Typography gutterBottom>
          {t('treatments:treatments.endTreatment.sendNextPhotos')}
        </Typography>
        <Typography gutterBottom>
          {t('treatments:treatments.endTreatment.downloadLastPhotos')}
        </Typography>
      </Grid>
      {end.map((angle) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={angle.name}>
          <Upload
            receiverId={patient.id}
            receiverType="patient"
            type="treatment_photo"
            backgroundImage={angle.background}
            meta={{
              angle: angle.name,
              serie: 'end_retainers'
            }}
            fileName={`photo-${angle.name}-end-${patient.reference}`}
            description={angle.display}
            retryable
            autoUpload
            hint={angle.angle && <img alt={angle.display} src={angle.hint} />}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default EndTreatmentPhotos;
