/* eslint-disable no-console */
import { v2 } from '../axios';

const { useState, useEffect, useCallback } = require('react');

const usePledg = () => {
  const [instance, setInstance] = useState(null);
  const [options, setOptions] = useState({});
  const [hasError, setHasError] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState(null);

  const onError = () => {
    setHasError(true);
  };

  const getPaymentMethods = async () => {
    try {
      const { data } = await v2.get('payment-methods');
      setPaymentMethods(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPaymentMethods();
  }, []);

  const initialize = useCallback((button, initialOptions) => {
    const formattedOptions = {
      ...initialOptions,
      onError: (e) => {
        onError();
        initialOptions.onError(e);
      }
    };
    setOptions(formattedOptions);
    const initializedInstance = new window.Pledg(button, formattedOptions);

    setInstance(initializedInstance);
  }, []);

  useEffect(() => {
    instance?.configure(options);
  }, [instance, options]);

  const configure = useCallback((newOptions) => {
    setOptions((currentOptions) => ({ ...currentOptions, ...newOptions }));
  }, []);

  return { instance, initialize, configure, hasError, paymentMethods };
};

export default usePledg;
