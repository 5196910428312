import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FilePreviewPdf = ({ file }) => {
  return (
    <Document file={file.url}>
      <Page pageNumber={1} />
    </Document>
  );
};

export default FilePreviewPdf;
