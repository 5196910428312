import React from 'react';

import { Switch, Route } from 'react-router-dom';

import List from './ListBookings';
import Create from './CreateBooking';

const Main = ({ auth, snackbar }) => {
  return (
    <Switch>
      <Route
        path="/bookings"
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={(props) => <List {...props} auth={auth} />}
      />
      <Route
        render={(props) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Create {...props} auth={auth} snackbar={snackbar} />
        )}
      />
    </Switch>
  );
};

export default Main;
