export const getFormErrors = (serverErrors) => {
  const errors = {};

  Object.keys(serverErrors).forEach((key) => {
    // eslint-disable-next-line prefer-destructuring
    errors[key] = serverErrors[key][0];
  });

  return errors;
};
