/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SnackbarContext from '../SnackbarContext';
import EndTreatmentPhotos from './endTreatmentPhotos/EndTreatmentPhotos';

import Aligners from './Aligners';
import ViewAligner from './ViewAligner';

function Main({ auth }) {
  return (
    <SnackbarContext.Consumer>
      {(snackbar) => (
        <Switch>
          <Route
            path="/traitement/fin"
            render={(props) => (
              <EndTreatmentPhotos
                {...props}
                snackbar={snackbar}
                auth={auth}
                patient={auth.user.patient}
              />
            )}
          />
          <Route
            path="/traitement/:serie"
            render={(props) => (
              <ViewAligner
                {...props}
                snackbar={snackbar}
                auth={auth}
                patient={auth.user.patient}
              />
            )}
          />
          <Route
            render={(props) => (
              <Aligners {...props} patient={auth.user.patient} auth={auth} />
            )}
          />
        </Switch>
      )}
    </SnackbarContext.Consumer>
  );
}

export default Main;
