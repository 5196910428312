import React from 'react';

import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const ClosedAccount = () => {
  const { t } = useTranslation('common');
  return (
    <Typography variant="h5" gutterBottom align="center">
      {t('common:common.accountCloseMessage')}
    </Typography>
  );
};

export default ClosedAccount;
