import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from './Dialog';

class DialogWithButton extends Component {
  state = {
    open: false,
    accepting: false
  };

  handleOpen = () => {
    this.setOpen(true);
  };

  handleClose = () => {
    this.setOpen(false);
  };

  handleAccept = async () => {
    const { onAccept } = this.props;
    this.setState({ accepting: true });
    await onAccept();
    this.setState({ accepting: false });
    this.setOpen(false);
  };

  setOpen = (open) => {
    this.setState({ open });
  };

  render() {
    const { buttonProps, onAccept, ...rest } = this.props;
    const { accepting, open } = this.state;

    return (
      <>
        <Button
          onClick={this.handleOpen}
          variant={buttonProps.variant || 'contained'}
          color={buttonProps.color || 'primary'}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...buttonProps}
        >
          {buttonProps.text}
        </Button>
        <Dialog
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          open={open}
          onCancel={this.handleClose}
          onAccept={onAccept && this.handleAccept}
          accepting={accepting}
        />
      </>
    );
  }
}

export default DialogWithButton;
