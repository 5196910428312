import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { Form } from 'react-final-form';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useTranslation } from 'react-i18next';
import NoSlots from './NoSlots';
import Confirm from './Confirm';
import ChooseDate from './ChooseDate';
import DialogConfirm from './DialogConfirm';

import MoreAvailabilities from './MoreAvailabilities';

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(2)
  }
});

const PracticianPanel = ({
  classes,
  practician,
  availabilities,
  getHours,
  onSubmit,
  onPanelChange,
  activePractician,
  index,
  auth,
  session
}) => {
  const { t } = useTranslation(['bookings', 'common']);
  const [showConfirm, setShowConfirm] = useState(false);
  const { patient } = auth.user;
  const isActivePractician =
    activePractician && activePractician.id === practician.id;
  const hasSlots = availabilities && availabilities.length;

  const validate = (values) => {
    const errors = {};

    if (session) return errors;

    if (!values.cgv) {
      errors.cgv = t('common:common.mandatory');
    }
    if (!values.cgu) {
      errors.cgu = t('common:common.mandatory');
    }
    if (!values.allow_practician_transfer_impression) {
      errors.allow_practician_transfer_impression = t(
        'common:common.mandatory'
      );
    }
    if (!values.allow_practician_transfer_data) {
      errors.allow_practician_transfer_data = t('common:common.mandatory');
    }

    return errors;
  };

  if (!hasSlots) {
    return (
      <NoSlots
        practician={practician}
        onPanelChange={onPanelChange}
        isActivePractician={isActivePractician}
        classes={classes}
        availabilities={availabilities}
        index={index}
      />
    );
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        practician_id: practician.id,
        date: availabilities && availabilities.length && availabilities[0].date,
        hour:
          availabilities && availabilities.length && availabilities[0].slots[0]
      }}
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        values,
        form,
        hasValidationErrors
      }) => {
        return (
          <form onSubmit={handleSubmit} className={classes.root}>
            <ExpansionPanel
              disabled={!availabilities}
              onChange={(e, expanded) => {
                onPanelChange(expanded, practician.id, form);
              }}
              expanded={isActivePractician || false}
            >
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Grid container>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography variant="subtitle1">
                      {`${index + 1}. ${practician.alias}`}
                    </Typography>
                    {practician.distance != null && (
                      <Typography variant="caption">
                        {Math.ceil(practician.distance)} km
                      </Typography>
                    )}
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Typography color="textSecondary">
                      {practician.address.address} -{' '}
                      {practician.address.postal_code} {practician.address.city}
                    </Typography>
                  </Grid>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Grid container spacing={2}>
                  {showConfirm ? (
                    <Confirm submitting={submitting} />
                  ) : (
                    <ChooseDate
                      form={form}
                      getHours={getHours}
                      values={values}
                      availabilities={availabilities}
                    />
                  )}
                </Grid>
              </ExpansionPanelDetails>
              <Divider />
              <ExpansionPanelActions>
                <Grid container spacing={2} justifyContent="center">
                  {showConfirm || session ? (
                    <DialogConfirm
                      setShowConfirm={setShowConfirm}
                      handleSubmit={handleSubmit}
                      practician={practician}
                      hasValidationErrors={hasValidationErrors}
                      patient={patient}
                      values={values}
                    />
                  ) : (
                    <Grid
                      item
                      xs={12}
                      justifyContent="space-between"
                      container
                      spacing={2}
                    >
                      {practician.accepts_booking_requests && (
                        <Grid item xs={12} sm={6} md={4}>
                          <MoreAvailabilities practician={practician} />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={6} md={4}>
                        <Button
                          onClick={async () => {
                            // refresh to check if deposit paid
                            await auth.fetchUser();
                            setShowConfirm(true);
                          }}
                          color="primary"
                          variant="contained"
                          autoFocus
                          fullWidth
                        >
                          {practician.availabilities_source === 'doctolib'
                            ? t(
                                'bookings:bookings.practicians.practicianPanel.availabilitiesSource.doctolib'
                              )
                            : t(
                                'bookings:bookings.practicians.practicianPanel.availabilitiesSource.notDoctolib'
                              )}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </ExpansionPanelActions>
            </ExpansionPanel>
          </form>
        );
      }}
    />
  );
};

export default withStyles(styles)(PracticianPanel);
