import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core/styles';

import parseISO from 'date-fns/parseISO';
import { withTranslation } from 'react-i18next';
import InteractiveRender from './TreatmentPlanInteractiveRenderer';
import StaticRender from './TreatmentPlanStaticRenderer';

import Loadable from './Loadable';
import axios from '../axios';
import { formatNumberToCurrency } from '../utils/payment';

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2)
  }
});

class TreatmentPlan extends Component {
  state = {
    isFetching: false,
    treatmentPlan: null
  };

  componentDidMount = async () => {
    await this.fetchTreatmentPlan();
  };

  fetchTreatmentPlan = async () => {
    const { data: treatmentPlan } = await axios.get(`treatment-plan`);

    this.setState({ treatmentPlan });
  };

  render() {
    const { classes, patient, t } = this.props;
    const { isFetching, treatmentPlan } = this.state;

    let { price } = patient;

    if (patient.treatment_type === 'simple') {
      price += 90;
    }

    if (!treatmentPlan) {
      return null;
    }

    return (
      <Paper className={classes.root}>
        <Typography variant="h5">
          {t('treatmentPlan:treatmentPlan.title')}
        </Typography>

        <Loadable
          loading={isFetching}
          message={t('treatmentPlan:treatmentPlan.loadingMessage')}
        >
          <Grid container justifyContent="center" spacing={1}>
            <Grid item xs={6} sm={3}>
              <Typography align="center" color="textSecondary">
                {t('treatmentPlan:treatmentPlan.numberOfAligner')}
              </Typography>
              <Typography align="center" variant="h6">
                {patient.nb_aligners}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography align="center" color="textSecondary">
                {t('treatmentPlan:treatmentPlan.treatmentDuration')}
              </Typography>
              <Typography align="center" variant="h6">
                {t('treatmentPlan:treatmentPlan.treatmentDurationValue', {
                  duration: patient.treatment_duration
                })}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography align="center" color="textSecondary">
                {t('treatmentPlan:treatmentPlan.availableSince')}
              </Typography>
              <Typography align="center" variant="h6">
                {t('common:common.date.detailed', {
                  date: parseISO(patient.decision_practician_at)
                })}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Typography align="center" color="textSecondary">
                {t('treatmentPlan:treatmentPlan.price')}
              </Typography>
              <Typography align="center" variant="h6">
                {formatNumberToCurrency(price)}
              </Typography>
            </Grid>
            {!!treatmentPlan.comments.length && (
              <Grid item xs={12}>
                <Typography color="textSecondary">
                  {t('treatmentPlan:treatmentPlan.remarks')}
                </Typography>
                {treatmentPlan.comments.map((c) => (
                  <Typography key={c.id}>{c.content}</Typography>
                ))}
              </Grid>
            )}

            <Grid item xs={12}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {treatmentPlan.is_complete &&
              treatmentPlan.available_at &&
              treatmentPlan.available ? (
                treatmentPlan.type === 'interactive' ? (
                  <InteractiveRender treatmentPlan={treatmentPlan} />
                ) : (
                  <StaticRender treatmentPlan={treatmentPlan} />
                )
              ) : (
                <Typography>
                  {t('treatmentPlan:treatmentPlan.notAvailable')}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Loadable>
      </Paper>
    );
  }
}
const TreatmentPlanWithaligner = withStyles(styles)(TreatmentPlan);
export default withTranslation(['treatmentPlan', 'common'])(
  TreatmentPlanWithaligner
);
