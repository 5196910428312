import {
  Divider,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MoreAvailabilities from './MoreAvailabilities';

const NoSlots = ({
  practician,
  onPanelChange,
  isActivePractician,
  availabilities,
  classes,
  index
}) => {
  const { t } = useTranslation('bookings');
  return (
    <ExpansionPanel
      disabled={!availabilities}
      onChange={(e, expanded) => {
        onPanelChange(expanded, practician.id);
      }}
      expanded={isActivePractician || false}
      className={classes.root}
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container>
          <Grid item md={6} sm={12} xs={12}>
            <Typography variant="subtitle1">
              {`${index + 1}. ${practician.alias}`}
            </Typography>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Typography color="textSecondary">
              {practician.address.address} - {practician.address.postal_code}{' '}
              {practician.address.city}
            </Typography>
          </Grid>
        </Grid>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography variant="subtitle1" color="textSecondary">
          {t('bookings:bookings.practicians.noSlots.message')}
        </Typography>
      </ExpansionPanelDetails>
      {practician.accepts_booking_requests && (
        <>
          <Divider />
          <ExpansionPanelActions>
            <MoreAvailabilities practician={practician} />
          </ExpansionPanelActions>
        </>
      )}
    </ExpansionPanel>
  );
};

export default NoSlots;
