/* eslint-disable react/require-default-props */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import Fab from '@material-ui/core/Fab';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CachedIcon from '@material-ui/icons/Cached';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '@material-ui/core/Modal';

import HelpIcon from '@material-ui/icons/Help';

import { withStyles } from '@material-ui/core/styles';

import grey from '@material-ui/core/colors/grey';

import { withTranslation } from 'react-i18next';
import FetchFile from './FetchFile';
import axios from '../axios';
import { uploadToS3 } from '../utils/vapor';
import Loadable from './Loadable';

const styles = (theme) => ({
  root: {
    textAlign: 'center',
    width: '100%'
  },
  content: {
    padding: theme.spacing(2)
  },
  dropzone: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed',
    borderColor: grey[300],
    height: 150,
    cursor: 'pointer',
    overflow: 'hidden',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat'
  },
  button: {
    margin: theme.spacing()
  },
  icon: {
    marginLeft: theme.spacing()
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  hint: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

class Upload extends Component {
  state = {
    file: null,
    isUploading: false,
    uploadedFile: null,
    hasFile: false,
    refreshFetch: false,
    hintOpen: false
  };

  handleDrop = (accepted) => {
    const { autoUpload } = this.props;
    const file = accepted[0];

    this.setState({ file }, async () => {
      if (autoUpload) {
        return this.handleSubmit();
      }
      return undefined;
    });
  };

  handleSubmit = async () => {
    const {
      receiverId,
      receiverType,
      resourceId,
      resourceType,
      type,
      meta,
      onSuccess
    } = this.props;
    const { file, refreshFetch } = this.state;

    try {
      this.setState({ isUploading: true });
      const uploadedFile = await uploadToS3(file);

      const data = {
        file_key: uploadedFile.key,
        receiver_type: receiverType,
        receiver_id: receiverId,
        type,
        ...(resourceId && { resource_id: resourceId }),
        ...(resourceType && { resource_type: resourceType }),
        ...(meta && { meta })
      };

      const response = await axios.post('files', data);

      this.setState({
        // eslint-disable-next-line react/no-unused-state
        uploadedFile: response.data,
        hasFile: true,
        retryMode: false,
        // eslint-disable-next-line react/no-access-state-in-setstate
        refreshFetch: !refreshFetch
      });

      if (onSuccess) {
        onSuccess(response.data);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      this.setState({ isUploading: false });
    }
  };

  onFetch = (file) => {
    const { onSuccess } = this.props;

    if (file && Object.keys(file).length) {
      this.setState({ hasFile: true, retryMode: false });

      if (onSuccess) {
        onSuccess(file);
      }
    }
  };

  render() {
    const {
      classes,
      description,
      retryable,
      autoUpload,
      onSuccess,
      readOnly,
      backgroundImage,
      hint,
      dropzoneLabel,
      t,
      ...rest
    } = this.props;
    const { file, hasFile, retryMode, isUploading, refreshFetch, hintOpen } =
      this.state;

    const retry = (
      <Tooltip title="Remplacer" placement="bottom">
        <Fab
          color="secondary"
          size="small"
          onClick={() => {
            this.setState({
              // eslint-disable-next-line react/no-unused-state
              uploaded: false,
              retryMode: true
            });
          }}
          className={classes.button}
        >
          <CachedIcon />
        </Fab>
      </Tooltip>
    );

    const fetch = (
      <FetchFile
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        hidden={retryMode}
        description={description}
        onSuccess={this.onFetch}
        actionButtons={
          !readOnly && <>{hasFile && !!retryable && !retryMode && retry}</>
        }
        refetch={refreshFetch}
        showNotFound={readOnly}
      />
    );

    const drop = (
      <>
        <Dropzone
          onDrop={this.handleDrop}
          multiple={false}
          disabled={isUploading}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              style={{
                backgroundImage: `url(${backgroundImage})`,
                justifyContent: backgroundImage ? 'flex-end' : 'space-around'
              }}
              className={classes.dropzone}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getRootProps()}
            >
              <Loadable
                loading={isUploading}
                message={t('common:common.uploading')}
              >
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <input {...getInputProps()} />
                {!backgroundImage && (
                  <CloudUploadIcon className={classes.icon} />
                )}
                <Typography
                  color={backgroundImage ? 'primary' : 'textSecondary'}
                  variant="subtitle2"
                >
                  {dropzoneLabel || t('common:common.clickToTakePicture')}
                </Typography>
                <Typography>{file && file.name}</Typography>
              </Loadable>
            </div>
          )}
        </Dropzone>

        {!autoUpload && (
          <Button
            onClick={this.handleSubmit}
            size="small"
            variant="contained"
            component="span"
            className={classes.button}
            color="secondary"
            disabled={!file}
          >
            Upload
          </Button>
        )}
      </>
    );

    const cancelRetryButton = retryMode && !isUploading && (
      <Button
        onClick={() => {
          this.setState({ retryMode: false });
        }}
        size="small"
        variant="contained"
        component="span"
        className={classes.button}
        color="secondary"
      >
        {t('common:common.cancel')} <ClearIcon />
      </Button>
    );

    const hintPopover = (
      <>
        <Modal
          className={classes.hint}
          open={hintOpen}
          onClick={() => {
            this.setState({ hintOpen: false });
          }}
          onClose={() => {
            this.setState({ hintOpen: false });
          }}
        >
          {hint}
        </Modal>
      </>
    );

    return (
      <Paper className={classes.root}>
        <div className={classes.content}>
          <Typography
            gutterBottom
            color="textSecondary"
            className={classes.description}
          >
            {description}{' '}
            {hint && (
              <HelpIcon
                className={classes.icon}
                onClick={() => {
                  this.setState({
                    hintOpen: true
                  });
                }}
              />
            )}
          </Typography>
          {hint && hintPopover}

          {fetch}
          {(retryMode || !hasFile) && !readOnly && drop}
          {retryMode && cancelRetryButton}
        </div>
      </Paper>
    );
  }
}

Upload.propTypes = {
  retryable: PropTypes.bool,
  autoUpload: PropTypes.bool,
  description: PropTypes.string.isRequired,
  receiverId: PropTypes.number.isRequired,
  receiverType: PropTypes.oneOf(['patient', 'practician']).isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.object,
  fileName: PropTypes.string.isRequired,
  readOnly: PropTypes.bool
};
const UploadWithStyles = withStyles(styles)(Upload);
export default withTranslation('common')(UploadWithStyles);
