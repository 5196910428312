import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const AlertDialog = ({
  open,
  dialogProps,
  onAccept,
  onCancel,
  cancelButtonProps = {},
  confirmButtonProps = {},
  accepting
}) => {
  const { text: cancelText, ...restCancelButtonProps } = cancelButtonProps;
  const { text: confirmText, ...restConfirmButtonProps } = confirmButtonProps;

  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogProps.title}</DialogTitle>
        <DialogContent>
          {dialogProps.description && (
            <DialogContentText id="alert-dialog-description">
              {dialogProps.description}
            </DialogContentText>
          )}
          {dialogProps.content}
        </DialogContent>
        <DialogActions>
          {onCancel && (
            <Button
              onClick={onCancel}
              color="secondary"
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...restCancelButtonProps}
              disabled={accepting}
            >
              {cancelText || 'Annuler'}
            </Button>
          )}
          {onAccept && (
            <Button
              onClick={onAccept}
              color="primary"
              autoFocus
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...restConfirmButtonProps}
              disabled={accepting}
            >
              {accepting ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                confirmText || 'Confirmer'
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
