import React, { Component } from 'react';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';
import Script from 'react-load-script';
import { LoadScript } from '@react-google-maps/api';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Loadable from '../Loadable';
import Map from './Map';
import Practicians from './practicians/Practicians';
import Search from './Search';

import { v2, v2Base } from '../../axios';

import { createCheckoutSessionForBooking } from '../../utils/stripe.utils';
import { getUrlParam } from '../../utils/router.utils';

const libraries = ['places'];
const styles = (theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    marginTop: theme.spacing(8)
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8)
  }
});

class CreateBooking extends Component {
  state = {
    practicians: [],
    fetchedPracticians: false,
    googleMapsLoaded: false,
    activePractician: null,
    userLocation: null,
    stripeLoaded: false,
    toConfirm: null,
    session: null,
    success: false
  };

  componentDidMount = async () => {
    await this.getBookingToConfirm();
    await this.fetchPracticians();
    await this.fetchSession();
  };

  handleSearchSelect = async (coordinates) => {
    const { practicians } = this.state;

    this.setState({ userLocation: coordinates });
    await this.fetchPracticians(coordinates);

    if (practicians.length) {
      this.setActivePractician(practicians[0].id);
    }
  };

  handleBookingCreated = async () => {
    const { snackbar, t, auth } = this.props;

    await auth.fetchUser();
    snackbar.open(t('bookings:bookings.createBooking.snackbar.success'));
    this.setState({ success: true });
  };

  getBookingToConfirm = async () => {
    const { auth } = this.props;

    if (!getUrlParam('source') || !auth.user.patient.booking_deposit_paid_at) {
      return;
    }

    const practicianId = getUrlParam('practician_id');
    const hour = getUrlParam('hour');
    const date = getUrlParam('date');
    this.setState({
      toConfirm: {
        practician_id: practicianId,
        hour,
        date
      }
    });
  };

  getPracticianById = (practicianId) => {
    const { practicians } = this.state;

    if (!practicians.length) {
      return null;
    }

    return practicians.find((practician) => practician.id === practicianId);
  };

  setActivePractician = (practicianId) => {
    const activePractician = this.getPracticianById(practicianId);

    this.setState({ activePractician });
  };

  fetchSession = async () => {
    const sessionSerie = getUrlParam('session');

    if (!sessionSerie) {
      return;
    }

    try {
      const session = await v2.get(`office-sessions/${sessionSerie}`);

      this.setState({ session });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  fetchPracticians = async (coordinates = null) => {
    const { session } = this.state;
    let params = {};

    if (coordinates) {
      params = {
        latitude: coordinates.lat,
        longitude: coordinates.lng
      };
    }

    if (session) {
      params.session = session;
    }

    const { data: practicians } = await v2Base.get('practicians', {
      params
    });

    this.setState({ practicians, fetchedPracticians: true });
  };

  render() {
    const { classes, snackbar, auth, t } = this.props;
    const { booking } = auth.user.patient;
    const {
      practicians,
      fetchedPracticians,
      googleMapsLoaded,
      activePractician,
      userLocation,
      stripeLoaded,
      toConfirm,
      session,
      success
    } = this.state;

    const isFromDoctolib = getUrlParam('source') === 'doctolib';
    const hasBookingWithoutConfirmation =
      booking && booking.status === 'confirmed' && !isFromDoctolib;

    if (hasBookingWithoutConfirmation || success) {
      return <Redirect to="/bookings" />;
    }

    return (
      <div>
        <Script
          url="https://js.stripe.com/v3/"
          onLoad={() => {
            this.setState({ stripeLoaded: true });
          }}
        />

        <LoadScript
          id="script-loader"
          libraries={libraries}
          googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API}
          onLoad={() => {
            this.setState({ googleMapsLoaded: true });
          }}
        >
          {googleMapsLoaded && (
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={10} md={8} lg={6}>
                <Search onSelect={this.handleSearchSelect} />
              </Grid>
            </Grid>
          )}

          {fetchedPracticians && !practicians.length ? (
            <Paper className={classes.paper}>
              <Typography align="center">
                {t(
                  'bookings:bookings.practicians.availabilities.notAvailable.message'
                )}
              </Typography>
            </Paper>
          ) : (
            <Loadable
              loading={
                !fetchedPracticians || !googleMapsLoaded || !stripeLoaded
              }
              message={t('bookings:bookings.availabilities.loading.message')}
            >
              {!!practicians.length && (
                <div className={classes.root}>
                  <Practicians
                    practicians={practicians}
                    activePractician={activePractician}
                    setActivePractician={this.setActivePractician}
                    onBook={this.handleBookingCreated}
                    snackbar={snackbar}
                    createCheckoutSession={createCheckoutSessionForBooking}
                    auth={auth}
                    toConfirm={toConfirm}
                    session={session}
                  />
                  <Map
                    practicians={practicians}
                    setActivePractician={this.setActivePractician}
                    activePractician={activePractician}
                    userLocation={userLocation}
                  />
                </div>
              )}
            </Loadable>
          )}
        </LoadScript>
      </div>
    );
  }
}
const CreateBookingWithStyles = withStyles(styles)(CreateBooking);
export default withTranslation('bookings')(CreateBookingWithStyles);
