/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import { v2 } from '../../axios';

const useCoupon = () => {
  const [coupon, setCoupon] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isApplying, setIsApplying] = useState(false);

  const apply = async (code) => {
    try {
      setIsApplying(true);
      const { data } = await v2.post('/coupons', { code });
      setCoupon(data);

      return data;
    } finally {
      setIsApplying(false);
    }
  };

  const get = async () => {
    try {
      setIsFetching(true);
      const { data: fetchedCoupon } = await v2.get('/coupons');
      if (fetchedCoupon?.code) {
        setCoupon(fetchedCoupon);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsFetching(false);
    }
  };

  const remove = async () => {
    try {
      setIsApplying(true);
      await v2.delete('coupons');
      setCoupon(null);
      await get();
    } catch (error) {
      console.error(error);
    } finally {
      setIsApplying(false);
    }
  };

  useEffect(() => {
    get();
  }, []);

  return { coupon, apply, get, remove, isFetching, isApplying };
};

export default useCoupon;
