import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatNumberToCurrency } from '../../../../../utils/payment';
import { StyledLine } from './MildPracticianBreakdown.styled';

const MildPracticianBreakdown = ({ checkoutInitializers }) => {
  const { t } = useTranslation('production');

  return (
    <Box mb={2}>
      <Box mb={2}>
        <Typography gutterBottom>
          {t(
            'production:production.payment.methods.stripe.mildPracticianBreakdown.message'
          )}
        </Typography>
      </Box>

      {checkoutInitializers.map((checkout, index) => (
        <Grid container key={checkout.type}>
          <Grid item xs={6}>
            <StyledLine paid={checkout.paid}>
              {`${index + 1}. ${
                checkout.type === 'local'
                  ? t(
                      'production:production.payment.methods.stripe.mildPracticianBreakdown.price.strippingAndOrAttachment'
                    )
                  : t(
                      'production:production.payment.methods.stripe.mildPracticianBreakdown.price.treatmentAligner'
                    )
              } ${
                checkout.paid
                  ? t(
                      'production:production.payment.methods.stripe.mildPracticianBreakdown.paid.message'
                    )
                  : ''
              }`}
            </StyledLine>
          </Grid>
          <Grid item xs={6}>
            <StyledLine align="right" paid={checkout.paid}>
              {formatNumberToCurrency(checkout.amount / 100)}
            </StyledLine>
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default MildPracticianBreakdown;
