/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import { Box, Button } from '@material-ui/core';
import { Link, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BookingRequest from './BookingRequest';
import ScriptLoader from '../scriptLoader/ScriptLoader';
import { v2 } from '../../axios';
import BookingRequestConfirmed from './BookingRequestConfirmed';
import {
  shouldUserPayDeposit,
  STATUS_ABANDONED,
  STATUS_CONFIRMED
} from '../../utils/bookingRequests.utils';
import Loadable from '../Loadable';

const Main = ({ auth, bookingDepositPaid }) => {
  const { t } = useTranslation('bookingRequests');

  const { params } = useRouteMatch();
  const [bookingRequest, setBookingRequest] = useState(null);

  useEffect(() => {
    const fetchBookingRequest = async () => {
      try {
        const { data: fetchedBookingRequest } = await v2.get(
          `/booking-requests/${params.code}`
        );
        setBookingRequest(fetchedBookingRequest);
      } catch (error) {
        console.error(error);
      }
    };
    fetchBookingRequest();
  }, [params.code]);

  if (bookingRequest?.status === STATUS_CONFIRMED) {
    return <BookingRequestConfirmed bookingRequest={bookingRequest} />;
  }

  if (bookingRequest?.status === STATUS_ABANDONED) {
    return (
      <Box p={2}>
        <Typography align="center" gutterBottom color="error">
          {t('bookingRequests:bookingRequests.abandoned.message')}
        </Typography>
        <Box display="flex" justifyContent="center" mt={2}>
          <Button color="primary" variant="contained" component={Link} to="/">
            {t('bookingRequests:bookingRequests.abandoned.button.title')}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Loadable loading={!bookingRequest}>
      <Box>
        <Box p={2}>
          <Typography align="center" variant="h4" gutterBottom>
            {t('bookingRequests:bookingRequests.title')}
          </Typography>
          {!bookingDepositPaid && shouldUserPayDeposit(auth.user) && (
            <>
              <Typography gutterBottom>
                {t('bookingRequests:bookingRequests.deposit.reason')}
              </Typography>
              <Typography gutterBottom>
                {t('bookingRequests:bookingRequests.deposit.refund')}
              </Typography>
            </>
          )}
        </Box>
        <Box mt={2} display="flex" justifyContent="center">
          <ScriptLoader url="https://js.stripe.com/v3/">
            <BookingRequest
              bookingRequest={bookingRequest}
              bookingDepositPaid={bookingDepositPaid}
              auth={auth}
            />
          </ScriptLoader>
        </Box>
      </Box>
    </Loadable>
  );
};

export default Main;
