import React from 'react';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import parseISO from 'date-fns/parseISO';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import OfficeSession from '../officeSessions/OfficeSession';

const Treatment = ({ patient }) => {
  const { t } = useTranslation(['dashboard', 'common']);
  return (
    <Box mb={2}>
      <Paper>
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">
                {t('dashboard:dashboard.treatment.title')}
              </Typography>
            </Grid>
            <OfficeSession patient={patient} />
            <Grid item xs={12} sm={4}>
              <Typography align="center" color="textSecondary">
                {t('dashboard:dashboard.treatment.aligners')}
              </Typography>
              <Typography align="center" variant="h6">
                {`${patient.actual_aligners.serie}/${patient.nb_aligners}`}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="center" color="textSecondary">
                {t('dashboard:dashboard.treatment.title')}{' '}
                {patient.actual_aligners.serie}
              </Typography>
              <Typography align="center" variant="h6">
                {t('common:common.date.detailed', {
                  date: parseISO(patient.actual_aligners.started_at)
                })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="center" color="textSecondary">
                {t('dashboard:dashboard.treatment.nextAligner')}
              </Typography>
              <Typography align="center" variant="h6">
                {t('common:common.date.detailed', {
                  date: parseISO(patient.actual_aligners.expected_end)
                })}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default Treatment;
