import React from 'react';
import InvoiceContent from './InvoiceContent';

const InvoiceAnnual = ({ invoice, generatePdf }) => {
  return (
    <InvoiceContent
      price={`${Number.parseFloat(invoice.amount / 100).toFixed(2)} €`}
      onDownload={async () => {
        await generatePdf(invoice.id, {}, 'facture-traitement.pdf');
      }}
      name="Facture traitement"
    />
  );
};

export default InvoiceAnnual;
