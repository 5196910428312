import React from 'react';
import { StyledFormControlLabel, StyledRadio } from './PaymentMethods.styled';

const PaymentMethod = ({ elementRef, plan, ...rest }) => {
  return (
    <StyledFormControlLabel
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      ref={elementRef}
      value={plan.name}
      control={<StyledRadio />}
    />
  );
};

export default PaymentMethod;
