import React, { useState } from 'react';
import { Box, Button, Grid, Paper, Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { parseISO } from 'date-fns';
import { createCheckoutSessionForBookingRequest } from '../../utils/stripe.utils';
import {
  shouldUserPayDeposit,
  STATUS_PENDING
} from '../../utils/bookingRequests.utils';
import { v2 } from '../../axios';

const BookingRequest = ({ auth, bookingRequest, bookingDepositPaid }) => {
  const { t } = useTranslation(['bookingRequests', 'common']);

  const [confirmed, setConfirmed] = useState(false);
  const handleConfirm = async () => {
    if (!bookingDepositPaid && shouldUserPayDeposit(auth.user)) {
      return createCheckoutSessionForBookingRequest(bookingRequest);
    }

    try {
      const { code } = bookingRequest;
      await v2.put('/booking-requests/confirm', {
        code
      });
      await auth.fetchUser();
      setConfirmed(true);
      return undefined;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return undefined;
    }
  };

  if (!bookingRequest) {
    return null;
  }

  if (confirmed) {
    return <Redirect to="/bookings" />;
  }

  return (
    <Paper elevation={3}>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              {t('bookingRequests:bookingRequests.booking.title')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="subtitle2"
              color="textSecondary"
            >
              {t('bookingRequests:bookingRequests.booking.informations.date')}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {t('common:common.date.detailed', {
                date: parseISO(bookingRequest.date)
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="subtitle2"
              color="textSecondary"
            >
              {t('bookingRequests:bookingRequests.booking.informations.time')}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {t('common:common.date.time', {
                date: parseISO(bookingRequest.date)
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              align="center"
              variant="subtitle2"
              color="textSecondary"
            >
              {t(
                'bookingRequests:bookingRequests.booking.informations.practician'
              )}
            </Typography>
            <Typography align="center" variant="subtitle2">
              {`Dr. ${bookingRequest.practician.fullname}`}
            </Typography>
          </Grid>
          {bookingRequest.status === STATUS_PENDING && (
            <Grid item xs={12} align="center">
              <Button
                color="primary"
                variant="contained"
                onClick={handleConfirm}
              >
                {t('bookingRequests:bookingRequests.confirm')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default BookingRequest;
