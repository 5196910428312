import React from 'react';

import SnackbarContext from '../SnackbarContext';

import TreatmentPlan from './TreatmentPlan';
import MedicalData from './medicalData/MedicalData';

const Main = ({ auth }) => {
  const { patient } = auth.user;

  return (
    <SnackbarContext.Consumer>
      {(snackbar) => {
        if (!patient.decision_practician_at) {
          return <MedicalData auth={auth} snackbar={snackbar} />;
        }

        return <TreatmentPlan auth={auth} snackbar={snackbar} />;
      }}
    </SnackbarContext.Consumer>
  );
};

export default Main;
