import React, { Fragment } from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Form, Field } from 'react-final-form';
import { Radio } from 'final-form-material-ui';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Checkbox from '../../form/Checkbox';
import TextField from '../../form/TextField';

import DialogWithButton from '../../DialogWithButton';

import questionsByCategories from '../../../questions';
import axios from '../../../axios';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
});

const Questions = ({ auth, snackbar, classes }) => {
  const { t } = useTranslation(['production', 'common', 'healthQuestions']);
  const handleSubmit = async (values) => {
    const data = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const category of questionsByCategories) {
      // eslint-disable-next-line no-restricted-syntax
      for (const question of category.questions) {
        if (question.type === 'radio') {
          data[question.name] = values[question.name];
        }

        if (question.type === 'checkbox') {
          data[question.name] = values[question.name] ? 'yes' : 'no';
        }
      }
    }

    data.allergies = values.allergies || '';
    data.other_concerns = values.other_concerns || '';

    try {
      await axios.post('/health-questions', data);
      snackbar.open(
        t('production:production.medicalData.questions.snackbar.success'),
        'success'
      );
      await auth.fetchUser();
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  const validate = (values) => {
    const errors = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const category of questionsByCategories) {
      // eslint-disable-next-line no-restricted-syntax
      for (const question of category.questions) {
        if (
          (question.type === 'radio' || question.type === 'textfield') &&
          !values[question.name]
        ) {
          errors[question.name] = t('common:common.mandatory');
        }
      }
    }

    if (
      values.has_allergies &&
      values.has_allergies === 'yes' &&
      !values.allergies
    ) {
      errors.allergies = t('common:common.mandatory');
    }
    if (
      values.has_other_concerns &&
      values.has_other_concerns === 'yes' &&
      !values.other_concerns
    ) {
      errors.other_concerns = t('common:common.mandatory');
    }

    return errors;
  };

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" gutterBottom>
        {t('production:production.medicalData.questions.title')}
      </Typography>
      <br />
      <Form
        onSubmit={handleSubmit}
        validate={validate}
        render={({ handleSubmit: onSubmit, values, errors, submitFailed }) => {
          return (
            <form onSubmit={onSubmit}>
              <Grid container spacing={2}>
                {questionsByCategories.map((category) => (
                  <Fragment key={category.name}>
                    <Grid item xs={12}>
                      <br />
                      <br />
                      <Typography variant="h6">
                        {t(
                          `healthQuestions:healthQuestions.${category.name}.title`
                        )}
                      </Typography>
                      <br />
                      {category.name === 'general_health' && (
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            {t(
                              'production:production.medicalData.questions.form.sections.generalHealth.formLabel'
                            )}
                          </FormLabel>
                        </FormControl>
                      )}
                    </Grid>
                    {category.questions.map((question) => {
                      return (
                        <Grid item xs={12} sm={12} md={6} key={question.name}>
                          {question.type === 'checkbox' ? (
                            <Field
                              name={question.name}
                              label={t(
                                `healthQuestions:healthQuestions.${category.name}.${question.name}`
                              )}
                              type="checkbox"
                              component={Checkbox}
                            />
                          ) : (
                            <FormControl component="fieldset">
                              <FormLabel
                                component="legend"
                                error={submitFailed && !!errors[question.name]}
                              >
                                {t(
                                  `healthQuestions:healthQuestions.${category.name}.${question.name}`
                                )}
                              </FormLabel>
                              <RadioGroup row>
                                <FormControlLabel
                                  label={t(
                                    'production:production.medicalData.questions.form.checkbox.true'
                                  )}
                                  control={
                                    <Field
                                      name={question.name}
                                      component={Radio}
                                      type="radio"
                                      value="yes"
                                    />
                                  }
                                />
                                <FormControlLabel
                                  label={t(
                                    'production:production.medicalData.questions.form.checkbox.false'
                                  )}
                                  control={
                                    <Field
                                      name={question.name}
                                      component={Radio}
                                      type="radio"
                                      value="no"
                                    />
                                  }
                                />
                              </RadioGroup>
                              {submitFailed && (
                                <FormHelperText error>
                                  {errors[question.name]}
                                </FormHelperText>
                              )}
                            </FormControl>
                          )}
                          {question.type === 'textfield' &&
                            values[question.name] === 'yes' && (
                              <Grid container>
                                <Grid item xs={12}>
                                  <Field
                                    name={question.textfieldName}
                                    component={TextField}
                                    fullWidth
                                    label={t(
                                      'production:production.medicalData.questions.form.specify'
                                    )}
                                    variant="outlined"
                                  />
                                </Grid>
                              </Grid>
                            )}
                        </Grid>
                      );
                    })}
                  </Fragment>
                ))}
                <Grid item xs={12} />
                <Grid item xs={12} sm={6} md={8} />
                <Grid item xs={12} sm={6} md={4}>
                  <DialogWithButton
                    onAccept={onSubmit}
                    buttonProps={{
                      size: 'small',
                      color: 'primary',
                      variant: 'contained',
                      text: t('common:common.send'),
                      fullWidth: true
                    }}
                    dialogProps={{
                      title: t(
                        'production:production.medicalData.questions.dialog.title'
                      ),
                      description: t(
                        'production:production.medicalData.questions.dialog.description'
                      )
                    }}
                    confirmButtonProps={{
                      text: t(
                        'production:production.medicalData.questions.dialog.confirmedButton.title'
                      ),
                      variant: 'contained'
                    }}
                  />
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </Paper>
  );
};

export default withStyles(styles)(Questions);
