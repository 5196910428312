/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import validator from 'validator';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Box } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import Loadable from '../Loadable';
import axios from '../../axios';

import { formatNumberToCurrency } from '../../utils/payment';

import Affiliations from './Affiliations';
import TextField from '../form/TextField';

import { getReferralLink } from './helpers';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  facebook: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(2)
  }
});

const Main = ({ snackbar, classes, auth }) => {
  const { t } = useTranslation(['referral', 'common']);
  const { referral } = auth.user.patient;

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = t('common:common.mandatory');
    }

    if (values.email && !validator.isEmail(values.email)) {
      errors.email = t('referral:referral.referral.emailErrorMessage');
    }

    return errors;
  };

  const handleSubmit = async ({ email }) => {
    try {
      await axios.get('send-referral', {
        params: {
          email
        }
      });
      snackbar.open(t('referral:referral.referral.snackbar.success'));
      return undefined;
    } catch ({ response }) {
      if (!response) {
        return undefined;
      }
      const errors = {};
      const { data } = response;
      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        errors[key] = data[key][0];
      });

      return errors;
    }
  };

  return (
    <Box>
      <Paper className={classes.root}>
        <Typography variant="h5" gutterBottom>
          {t('referral:referral.referral.title')}
        </Typography>
        <Typography>
          <Trans t={t} i18nKey="referral:referral.referral.content">
            <span>Recevez un chèque cadeau de</span>
            <strong>
              {{
                referrerAmount: formatNumberToCurrency(
                  referral.referrer_treatment_amount / 100
                )
              }}
            </strong>
            <span>
              parmi
              <a href={getReferralLink(auth)} target="_blank" rel="noreferrer">
                ces marques
              </a>
            </span>
            <span>
              en parrainant vos amis. Ils bénéficieront d'une remise de
            </span>
            <strong>
              {{
                remise: formatNumberToCurrency(
                  referral.affiliate_treatment_amount / 100
                )
              }}
            </strong>
            <span>sur leur traitement Joovence.</span>
          </Trans>
        </Typography>
        <br />
        <br />

        <Typography align="center" variant="subtitle1">
          {t('referral:referral.referral.codeTitle')}
        </Typography>
        <CopyToClipboard
          text={auth.user.patient.referral.code}
          onCopy={() => {
            snackbar.open(t('referral:referral.referral.copySnackbar'));
          }}
        >
          <Typography align="center" variant="h6">
            {auth.user.patient.referral.code}
            <IconButton color="primary" aria-label="FileCopy">
              <FileCopyIcon />
            </IconButton>
          </Typography>
        </CopyToClipboard>

        <Box mt={4}>
          <Typography align="center" variant="subtitle1">
            {t('referral:referral.referral.referFriend')}
          </Typography>
          <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({
              handleSubmit: onSubmit,
              submitting,
              hasValidationErrors
            }) => (
              <form onSubmit={onSubmit} className={classes.form}>
                <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Field
                      name="email"
                      component={TextField}
                      type="text"
                      label={t('referral:referral.referral.emailLabel')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} />

                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                      disabled={submitting || hasValidationErrors}
                    >
                      <Loadable loading={submitting} noMessage size={18}>
                        {t('common:common.send')}
                      </Loadable>
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          />
        </Box>
      </Paper>

      <Affiliations referral={auth?.user.patient.referral} />
    </Box>
  );
};
export default withStyles(styles)(Main);
