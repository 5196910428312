import React, { Fragment, Component } from 'react';

import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import Booking from './Booking';
import Coupon from '../coupons/Coupon';
import axios from '../../axios';

class ListBookings extends Component {
  state = {
    bookings: []
  };

  async componentDidMount() {
    this.fetchBookings();
  }

  fetchBookings = async () => {
    try {
      const { data: bookings } = await axios.get('bookings');

      this.setState({ bookings });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  render() {
    const { bookings } = this.state;
    const { t } = this.props;

    return (
      <>
        <Typography variant="h5" gutterBottom>
          {t('bookings:bookings.list.title')}
        </Typography>
        <Box mb={2}>
          <Coupon />
        </Box>
        {bookings.map((booking) => (
          <Booking booking={booking} key={booking.id} />
        ))}
      </>
    );
  }
}

export default withTranslation('bookings')(ListBookings);
