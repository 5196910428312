import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import invoiceImg from '../../assets/images/invoice.svg';

const styles = (theme) => ({
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'contain',
    marginTop: theme.spacing()
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const InvoiceContent = ({ classes, price, name, onDownload }) => {
  const { t } = useTranslation('invoices');
  const [isFetching, setIsFetching] = useState(false);

  const handleDownload = async () => {
    setIsFetching(true);
    await onDownload();
    setIsFetching(false);
  };

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card>
        <CardMedia
          component="img"
          alt="Invoice"
          className={classes.media}
          height="140"
          image={invoiceImg}
          title={t('invoices:invoices.invoiceContent.card.title')}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {price}
          </Typography>
          <Typography component="p">{name}</Typography>
        </CardContent>

        <CardActions className={classes.actions}>
          <Button
            disabled={isFetching}
            onClick={handleDownload}
            variant="contained"
            color="primary"
            size="small"
          >
            {isFetching ? (
              <CircularProgress size={20} style={{ color: 'white' }} />
            ) : (
              t('invoices:invoices.invoiceContent.cardAction.button.text')
            )}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default withStyles(styles)(InvoiceContent);
