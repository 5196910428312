import React, { useContext, useState } from 'react';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import parseISO from 'date-fns/parseISO';

import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DialogWithButton from '../DialogWithButton';
import { v2 } from '../../axios';
import SnackbarContext from '../SnackbarContext';
import { STATUS_CANCELED, STATUS_NOT_HONORED } from './utils';
import { DoctolibConfirmWarning } from './DoctolibConfirmWarning';

const Booking = ({ booking: intialBooking, withTitle = true }) => {
  const { t } = useTranslation(['bookings', 'common']);
  const [booking, setBooking] = useState(intialBooking);
  const snackbar = useContext(SnackbarContext);

  const onCancel = async () => {
    try {
      const { data: canceledBooking } = await v2.post(
        `bookings/${booking.id}/cancel`
      );

      setBooking(canceledBooking);
      snackbar.open(
        t('bookings:bookings.viewBooking.canceled.snackbar.success'),
        'success'
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  return (
    <Box mb={2}>
      <Paper>
        <Box p={2}>
          <Grid container>
            {withTitle && (
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="center"
                  color={
                    booking.status === STATUS_CANCELED ||
                    booking.status === STATUS_NOT_HONORED
                      ? 'error'
                      : 'initial'
                  }
                >
                  {`${t(`bookings:bookings.type.${booking.type}`)} - ${t(
                    `bookings:bookings.status.${booking.status}`
                  )}`}
                </Typography>
                <br />
                <br />
              </Grid>
            )}
            <Grid item xs={12} sm={4}>
              <Typography align="center" color="textSecondary">
                {t('bookings:bookings.viewBooking.informations.date')}
              </Typography>
              <Typography align="center" variant="subtitle1">
                {t('common:common.date.datetime', {
                  date: parseISO(booking.date)
                })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="center" color="textSecondary">
                {t('bookings:bookings.viewBooking.informations.practician')}
              </Typography>
              <Typography align="center" variant="subtitle1">
                Dr. {booking.practician.fullname}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography align="center" color="textSecondary">
                {t('bookings:bookings.viewBooking.informations.address')}
              </Typography>
              <Typography align="center" variant="subtitle1">
                {booking.practician.address.address}{' '}
                {booking.practician.address.address2}
              </Typography>
              <Typography align="center" variant="subtitle1">
                {booking.practician.address.postal_code}{' '}
                {booking.practician.address.city}
              </Typography>
              <Typography align="center" variant="subtitle1">
                {booking.practician.address.address3}
              </Typography>
            </Grid>
          </Grid>
          {booking.status === 'confirmed' && booking.source === 'doctolib' && (
            <DoctolibConfirmWarning booking={booking} />
          )}
          {booking.is_canceled && booking.source === 'doctolib' && (
            <Box mt={2}>
              <Typography align="center" color="error">
                {t(
                  'bookings:bookings.viewBooking.canceled.dialog.doctolib.message'
                )}
              </Typography>
            </Box>
          )}
          {booking.is_cancelable && (
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              alignItems="flex-end"
            >
              <Grid item xs={10} sm={6} md={5} lg={3}>
                <Box mt={2}>
                  <DialogWithButton
                    onAccept={onCancel}
                    buttonProps={{
                      color: 'secondary',
                      text: t(
                        'bookings:bookings.viewBooking.canceled.dialog.title'
                      ),
                      fullWidth: true
                    }}
                    dialogProps={{
                      title: t(
                        'bookings:bookings.viewBooking.canceled.dialog.title'
                      ),
                      description: t(
                        'bookings:bookings.viewBooking.canceled.dialog.description'
                      )
                    }}
                    cancelButtonProps={{
                      text: 'Fermer'
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default Booking;
