import React, { Component, Fragment } from 'react';
import classnames from 'classnames';

import Chip from '@material-ui/core/Chip';
import FormLabel from '@material-ui/core/FormLabel';
import { Field } from 'react-final-form';

import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Radio from '../form/Radio';

const styles = (theme) => ({
  chip: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&:hover, &:focus': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white'
    }
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  field: {
    display: 'none'
  },
  label: {
    marginTop: theme.spacing()
  }
});

class Hours extends Component {
  changeHour = (hour) => {
    const { form } = this.props;

    form.change('hour', hour);
  };

  render() {
    const { hours, classes, activeHour, t } = this.props;

    return (
      <div>
        <FormLabel className={classes.label} component="legend">
          {t('bookings:bookings.practicians.availabilities.time')}
        </FormLabel>
        {hours.map((hour, index) => {
          const isActive = activeHour === hour;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              <Field
                className={classes.field}
                name="hour"
                component={Radio}
                type="radio"
                value={hour}
              />
              <Chip
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                label={hour}
                onClick={() => this.changeHour(hour)}
                className={classnames(
                  classes.chip,
                  isActive ? classes.active : null
                )}
              />
            </Fragment>
          );
        })}
      </div>
    );
  }
}
const HoursWithStyles = withStyles(styles)(Hours);
export default withTranslation('bookings')(HoursWithStyles);
