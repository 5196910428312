import React, { useState } from 'react';
import { Button, Grid, MenuItem } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import Checkbox from '../../form/Checkbox';
import Select from '../../form/Select';
import axios from '../../../axios';
import DatePicker from '../../form/DatePicker';

const StartTreatment = ({ snackbar, onStart }) => {
  const { t } = useTranslation(['production', 'common']);
  const [alreadyStarted, setAlreadyStarted] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.read) {
      errors.read = t('common:common.mandatory');
    }
    if (!values.received) {
      errors.received = t('common:common.mandatory');
    }

    if (alreadyStarted) {
      if (!values.serie) {
        errors.serie = t('common:common.mandatory');
      }
      if (!values.started_at) {
        errors.started_at = t('common:common.mandatory');
      }
    }

    return errors;
  };

  const startTreatment = async (values) => {
    try {
      await axios.get(`start-treatment`, {
        params: {
          ...values,
          ...(values.started_at && {
            started_at: t('common:common.date.datetime', {
              date: format(values.started_at, 'yyyy-MM-dd HH:mm:ss')
            })
          }),
          read: values.read ? 1 : 0,
          received: values.received ? 1 : 0
        }
      });

      snackbar.open(t('production:production.startTreatment.snackbar.success'));

      await onStart();
    } catch (error) {
      snackbar.open(
        t('production:production.startTreatment.snackbar.error'),
        'error'
      );
    }
  };

  return (
    <Form
      onSubmit={startTreatment}
      validate={validate}
      render={({ handleSubmit, invalid, form }) => (
        <form onSubmit={handleSubmit}>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item xs={12}>
              <Field
                name="received"
                label={t(
                  'production:production.startTreatment.form.field.name.received.label'
                )}
                component={Checkbox}
                type="checkbox"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name="read"
                label={t(
                  'production:production.startTreatment.form.field.name.read.label'
                )}
                component={Checkbox}
                type="checkbox"
              />
            </Grid>
            {!!alreadyStarted && (
              <Grid item xs={12} container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="serie"
                    label={t(
                      'production:production.startTreatment.form.field.name.serie.label'
                    )}
                    formControlProps={{
                      fullWidth: true
                    }}
                    component={Select}
                  >
                    {[...Array(10).keys()].map((serie, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <MenuItem key={index} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Field>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} sm={6} md={4}>
                  <Field
                    name="started_at"
                    label={t(
                      'production:production.startTreatment.form.field.name.startedAT.label'
                    )}
                    render={(props) => (
                      <DatePicker
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...props}
                        disableFuture
                        clearable
                        format="dd/MM/yyyy"
                        fullWidth
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4} lg={3}>
              {alreadyStarted ? (
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => {
                    form.change('serie', null);
                    form.change('started_at', null);
                    setAlreadyStarted(false);
                  }}
                >
                  {t(
                    'production:production.startTreatment.form.button.alreadyStarted.text'
                  )}
                </Button>
              ) : (
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={() => setAlreadyStarted(true)}
                >
                  {t(
                    'production:production.startTreatment.form.button.notStarted.text'
                  )}
                </Button>
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                disabled={invalid}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
              >
                {alreadyStarted
                  ? t(
                      'production:production.startTreatment.form.confirmButton.alreadyStarted.text'
                    )
                  : t(
                      'production:production.startTreatment.form.confirmButton.notStarted.text'
                    )}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default StartTreatment;
