import React, { Component } from 'react';

import axios from '../../axios';

import initializeIntercom from '../../intercom';
import { redirectToV2 } from '../../utils/router.utils';

const AuthContext = React.createContext({
  user: null,
  fetchedUser: false
});

class AuthProvider extends Component {
  state = {
    user: null,
    fetchedUser: false
  };

  componentDidMount = async () => {
    this.fetchUser();
  };

  setUser = (user) => {
    this.setState({ user }, () => {
      initializeIntercom(user);
    });
  };

  fetchUser = async () => {
    try {
      const { data: user } = await axios.get('/auth/user');

      if (user.patient.type === 'external') {
        redirectToV2();
      } else {
        this.setUser(user);
        this.setState({ fetchedUser: true });
      }
    } catch (error) {
      this.setState({ fetchedUser: true });
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  logout = async () => {
    try {
      await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`);

      this.setState({ user: null });
      // eslint-disable-next-line no-empty
    } catch (error) {}
  };

  render() {
    const { children } = this.props;

    return (
      <AuthContext.Provider
        value={{
          ...this.state,
          logout: this.logout,
          fetchUser: this.fetchUser
        }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

export { AuthContext as default, AuthProvider };
