import React from 'react';
import Typography from '@material-ui/core/Typography';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';
import Paper from '@material-ui/core/Paper';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2),
      flex: '1 1'
    },
    display: 'flex',
    minHeight: '50vh'
  }
});

const Map = ({
  classes,
  practicians,
  activePractician,
  setActivePractician,
  userLocation
}) => {
  const getCenter = () => {
    if (!activePractician) {
      return {
        lat: 48.864716, // center paris
        lng: 2.349014
      };
    }

    return {
      lat: Number(activePractician.address.latitude),
      lng: Number(activePractician.address.longitude)
    };
  };

  if (!practicians) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <GoogleMap
        zoom={11}
        center={getCenter()}
        id="pro-map"
        mapContainerStyle={{ minHeight: `50vh`, width: `100%` }}
      >
        {userLocation && (
          <Marker
            icon={{
              url: `${process.env.PUBLIC_URL}/images/my-location-icon.png`,
              labelOrigin: {
                x: 32,
                y: 20
              },
              scaledSize: {
                width: 20,
                height: 20
              }
            }}
            label={{
              text: `U`,
              color: 'white'
            }}
            position={{
              lat: parseFloat(userLocation.lat),
              lng: parseFloat(userLocation.lng)
            }}
          />
        )}
        {!!practicians.length &&
          practicians.map((practician, index) => (
            <Marker
              key={practician.id}
              icon={{
                url:
                  process.env.PUBLIC_URL +
                  (activePractician && activePractician.id === practician.id
                    ? `/images/joovence-station-map-marker.png`
                    : `/images/joovence-station-map-marker-blue.png`),
                scaledSize: {
                  width: 75,
                  height: 75
                },
                labelOrigin: {
                  x: 38,
                  y: 24
                }
              }}
              label={{
                text: `${index + 1}`,
                color:
                  activePractician && activePractician.id === practician.id
                    ? '#5CAAAE'
                    : '#EE737F',
                fontWeight: 'bold'
              }}
              position={{
                lat: parseFloat(practician.address.latitude),
                lng: parseFloat(practician.address.longitude)
              }}
              onClick={() => {
                setActivePractician(practician.id);
              }}
            >
              {activePractician && activePractician.id === practician.id && (
                <InfoWindow
                  position={{
                    lat: parseFloat(practician.address.latitude),
                    lng: parseFloat(practician.address.longitude)
                  }}
                  onCloseClick={null}
                >
                  <div>
                    <Typography variant="h6">
                      {`${index + 1}. ${practician.alias}`}
                    </Typography>
                    <Typography>{practician.address.address}</Typography>
                    <Typography>
                      {`${practician.address.postal_code} ${practician.address.city}`}
                    </Typography>
                    {practician.distance != null && (
                      <Typography variant="caption">
                        {Math.ceil(practician.distance)} km
                      </Typography>
                    )}
                  </div>
                </InfoWindow>
              )}
            </Marker>
          ))}
      </GoogleMap>
    </Paper>
  );
};

export default withStyles(styles)(Map);
