import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  width: 100%;
`;

const FilePreviewImage = ({ src, alt }) => {
  return <StyledImage style={{ width: '100%' }} src={src} alt={alt} />;
};

export default FilePreviewImage;
