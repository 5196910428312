import { COUNTRIES } from './vars';

const formatEndOfPhoneNumber = (phoneNumber) => {
  return phoneNumber.match(/.{1,2}/g).join(' ');
};

export const formatPhoneNumber = (phoneNumber, locale) => {
  switch (locale) {
    case COUNTRIES.COUNTRY_FR:
    case COUNTRIES.COUNTRY_BE: {
      const endOfPhoneNumberFr = phoneNumber.substr(3);
      return `0${phoneNumber.substr(2, 1)} ${formatEndOfPhoneNumber(
        endOfPhoneNumberFr
      )}`;
    }
    case COUNTRIES.COUNTRY_ES: {
      const endOfPhoneNumberEs = phoneNumber.substr(2);
      return `${endOfPhoneNumberEs.match(/.{1,3}/g).join(' ')}`;
    }
    default:
      return phoneNumber;
  }
};
