import React from 'react';
import SnackbarContext from '../SnackbarContext';

import Treatment from './Treatment';
import ChangeAligner from './ChangeAligner';
import TreatmentPlan from '../TreatmentPlan';

const Main = ({ auth }) => {
  const { patient } = auth.user;
  return (
    <SnackbarContext.Consumer>
      {(snackbar) => (
        <>
          {patient.actual_aligners.can_be_changed ? (
            <ChangeAligner patient={patient} auth={auth} snackbar={snackbar} />
          ) : (
            <>
              <Treatment patient={patient} />
              <TreatmentPlan patient={patient} />
            </>
          )}
        </>
      )}
    </SnackbarContext.Consumer>
  );
};

export default Main;
