/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import Grid from '@material-ui/core/Grid';

import { Form, Field } from 'react-final-form';

import parseISO from 'date-fns/parseISO';
import { withTranslation } from 'react-i18next';
import { format } from 'date-fns';
import axios from '../../axios';
import DatePicker from '../form/DatePicker';

class EditAligner extends Component {
  state = {
    updated: false
  };

  onSubmit = async (values) => {
    const { aligner, fetchAligner, snackbar, t } = this.props;
    console.log(values);
    const data = {
      started_at: format(values.started_at, 'yyyy-MM-dd HH:mm:ss')
    };

    if (values.finished_at) {
      data.finished_at = format(values.finished_at, 'yyyy-MM-dd HH:mm:ss');
    }

    console.log(data);

    try {
      await axios.put(`aligner-by-serie/${aligner.serie}`, data);
      snackbar.open(
        t('treatments:treatments.editAligner.snackbar.success'),
        'success'
      );
      await fetchAligner();

      this.setState({ updated: true });
      return undefined;
    } catch ({ response: { data: responseData } }) {
      const errors = {};

      Object.keys(data).forEach((key) => {
        // eslint-disable-next-line prefer-destructuring
        errors[key] = responseData[key][0];
      });

      return errors;
    }
  };

  render() {
    const { aligner, t } = this.props;
    const { updated } = this.state;

    if (!aligner) {
      return null;
    }

    if (updated) {
      return <Redirect to={`/traitement/${aligner.serie}`} />;
    }

    return (
      <Form
        onSubmit={this.onSubmit}
        initialValues={{
          started_at: parseISO(aligner.started_at),
          finished_at: aligner.finished_at && parseISO(aligner.finished_at)
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h6" component="h2" align="center">
                      {t('treatments:treatments.editAligner.title')}{' '}
                      {aligner.serie}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography align="center" color="textSecondary">
                      {t(
                        'treatments:treatments.editAligner.card.startedAt.title'
                      )}
                    </Typography>
                    <Field
                      name="started_at"
                      label={t(
                        'treatments:treatments.editAligner.card.startedAt.label'
                      )}
                      render={(props) => (
                        <DatePicker
                          {...props}
                          // clearable={true}
                          format="dd/MM/yyyy"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>

                  {aligner.finished_at && (
                    <Grid item xs={12} sm={6}>
                      <Typography align="center" color="textSecondary">
                        {t(
                          'treatments:treatments.editAligner.card.finishedAt.title'
                        )}
                      </Typography>
                      <Field
                        name="finished_at"
                        label={t(
                          'treatments:treatments.editAligner.card.finishedAt.label'
                        )}
                        render={(props) => (
                          <DatePicker
                            {...props}
                            // clearable={true}
                            format="dd/MM/yyyy"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  )}
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  size="small"
                  variant="contained"
                  component={Link}
                  to={`/traitement/${aligner.serie}`}
                  color="secondary"
                >
                  {t('treatments:treatments.editAligner.cancel.button.text')}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  size="small"
                >
                  {t('treatments:treatments.editAligner.save.button.text')}
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      />
    );
  }
}

export default withTranslation(['treatments', 'common'])(EditAligner);
