/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Box, Link, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCoupon from './useCoupon';
import Dialog from '../Dialog';
import CouponForm from './CouponForm';
import { getFormErrors } from '../../utils/errors.utils';
import Loadable from '../Loadable';
import { formatNumberToCurrency } from '../../utils/payment';

const Coupon = ({ onApply, onRemove, onFetch }) => {
  const { t } = useTranslation('coupons');

  const { coupon, apply, remove, isFetching, isApplying } = useCoupon();
  const [open, setOpen] = useState(false);

  const handleSubmit = async ({ code }) => {
    try {
      const appliedCoupon = await apply(code);
      if (onApply) {
        await onApply(appliedCoupon);
      }
      return undefined;
    } catch (error) {
      if (!error?.response) {
        return undefined;
      }
      return getFormErrors(error.response.data.errors);
    }
  };

  const handleRemove = async () => {
    try {
      await remove();
      if (onRemove) {
        await onRemove();
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    if (onFetch) {
      onFetch(coupon);
    }
  }, [coupon, onFetch]);

  const handleClick = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  return (
    <>
      <Typography align="center">
        <Link href="#" onClick={handleClick}>
          {t('coupons:coupons.coupon.title')}
        </Link>
      </Typography>

      <Dialog
        open={open}
        onCancel={() => setOpen(false)}
        accepting={isApplying}
        dialogProps={{
          title: t('coupons:coupons.coupon.dialog.title'),
          content: (
            <Loadable loading={isFetching}>
              {coupon && (
                <div>
                  <Typography color="primary">
                    {t('coupons:coupons.coupon.dialog.applied', {
                      code: coupon.code,
                      amount: formatNumberToCurrency(coupon.amount / 100)
                    })}
                  </Typography>
                  <Box mt={2}>
                    <Typography align="center">
                      <Link href="#" onClick={handleRemove}>
                        {t('coupons:coupons.coupon.dialog.remove')}
                      </Link>
                    </Typography>
                  </Box>
                </div>
              )}
              <CouponForm onSubmit={handleSubmit} />
            </Loadable>
          )
        }}
        cancelButtonProps={{ text: t('coupons:coupons.coupon.dialog.close') }}
      />
    </>
  );
};

export default Coupon;
