/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

const AcceptCGV = ({ onChange, accepted }) => {
  const { t } = useTranslation('production');

  return (
    <Box mt={2}>
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              name="accepted_cgv"
              onChange={onChange}
              checked={!!accepted}
            />
          }
          label={
            <Typography>
              <Trans
                t={t}
                i18nKey="production:production.payment.acceptCGV.label"
              >
                J'accepte les
                <a
                  href="https://www.joovence.com/cgv-patient"
                  target="_blank"
                  rel="noreferrer"
                >
                  Conditions Générales Vente
                </a>
                de Joovence
              </Trans>
            </Typography>
          }
        />
      </FormControl>
    </Box>
  );
};

export default AcceptCGV;
