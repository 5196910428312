/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { v2 } from '../../../../../axios';
import Loadable from '../../../../Loadable';
import { StyledButton, StyledLabel } from './Stripe.styled';
import { formatNumberToCurrency } from '../../../../../utils/payment';
import MildPracticianBreakdown from './MildPracticianBreakdown';

const Stripe = ({ auth }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [checkoutInitializers, setCheckoutInitializers] = useState([]);
  const { treatment_type: treatmentType } = auth.user.patient;

  useEffect(() => {
    const getInitializers = async () => {
      const { data: initializers } = await v2.get(
        'treatment-checkout-sessions'
      );

      setCheckoutInitializers(initializers);
    };

    getInitializers();
  }, []);

  const createCheckoutSession = async (checkout) => {
    try {
      setIsLoading(true);
      const { data } = await v2.post('treatment-checkout-sessions', checkout);

      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC, {
        stripeAccount: data.stripe_account_id
      });

      stripe
        .redirectToCheckout({
          sessionId: data.checkout_session.id
        })
        .then((result) => {
          console.error(result.error.message);
        });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  return (
    <>
      {treatmentType === 'mild' && (
        <MildPracticianBreakdown checkoutInitializers={checkoutInitializers} />
      )}
      <Grid container spacing={2} justifyContent="center">
        {checkoutInitializers
          .filter((c) => !c.paid)
          .map((checkout, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <StyledButton
                size="large"
                variant="contained"
                color="primary"
                onClick={() => createCheckoutSession(checkout)}
                disabled={isLoading}
                fullWidth
              >
                <StyledLabel>
                  {`${
                    treatmentType === 'mild' ? `${index + 1}. ` : ''
                  }Payer ${formatNumberToCurrency(checkout.amount / 100)}`}
                </StyledLabel>

                {isLoading ? (
                  <Loadable loading size={20} noMessage />
                ) : (
                  <ArrowForwardIcon />
                )}
              </StyledButton>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default Stripe;
