import { Grid, MenuItem } from '@material-ui/core';
import React from 'react';
import { Field } from 'react-final-form';
import parseISO from 'date-fns/parseISO';
import { withTranslation } from 'react-i18next';
import Select from '../../form/Select';
import Hours from '../Hours';

const ChooseDate = ({ form, getHours, values, availabilities, t }) => {
  if (!availabilities || !availabilities.length) return null;
  return (
    <>
      <Grid item md={6} sm={12} xs={12}>
        <Field
          name="date"
          label={t('bookings:bookings.practicians.availabilities.date')}
          formControlProps={{
            fullWidth: true
          }}
          component={Select}
          onSelect={(e) => {
            form.change('hour', getHours(e.target.value)[0]);
          }}
        >
          {availabilities.map((availability) => (
            <MenuItem key={availability.date} value={availability.date}>
              {t('common:common.date.detailed', {
                date: parseISO(availability.date)
              })}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        {values.date && (
          <Hours
            form={form}
            activeHour={values.hour}
            hours={getHours(values.date)}
          />
        )}
      </Grid>
    </>
  );
};

export default withTranslation(['bookings', 'common'])(ChooseDate);
