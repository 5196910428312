/* eslint-disable no-irregular-whitespace */
const questionsByCategories = [
  {
    name: 'personal_dental_health',
    questions: [
      {
        name: 'cleaned_professional_last_6_months',
        type: 'radio'
      },
      {
        name: 'xray_last_12_months',
        type: 'radio'
      },
      {
        name: 'issues_detected_fixed',
        type: 'radio'
      },
      {
        name: 'xray_no_shortened_tooth_roots',
        type: 'radio'
      },
      {
        name: 'xray_no_impacted_teeth_jaws',
        type: 'radio'
      },
      {
        name: 'no_gum_disease',
        type: 'radio'
      },
      {
        name: 'no_oral_cancer',
        type: 'radio'
      },
      {
        name: 'no_pain_teeth',
        type: 'radio'
      },
      {
        name: 'no_pain_jaws',
        type: 'radio'
      },
      {
        name: 'no_tmj',
        type: 'radio'
      },
      {
        name: 'no_loose_teeth',
        type: 'radio'
      },
      {
        name: 'no_implants_crowns_bridges_front',
        type: 'radio'
      },
      {
        name: 'no_implants_crowns_bridges',
        type: 'radio'
      }
    ]
  },
  {
    name: 'general_health',
    questions: [
      {
        name: 'rheumatic_fever',
        type: 'checkbox'
      },
      {
        name: 'angina',
        type: 'checkbox'
      },
      {
        name: 'heart_surgery',
        type: 'checkbox'
      },
      {
        name: 'heart_attack',
        type: 'checkbox'
      },
      {
        name: 'high_low_blood_pressure',
        type: 'checkbox'
      },
      {
        name: 'pacemaker',
        type: 'checkbox'
      },
      {
        name: 'heart_murmur',
        type: 'checkbox'
      },
      {
        name: 'replacement_heart_valve',
        type: 'checkbox'
      },
      {
        name: 'asthma',
        type: 'checkbox'
      },
      {
        name: 'bronchitis',
        type: 'checkbox'
      },
      {
        name: 'tuberculosis',
        type: 'checkbox'
      },
      {
        name: 'epilepsy',
        type: 'checkbox'
      },
      {
        name: 'diabetes',
        type: 'checkbox'
      },
      {
        name: 'family_history_diabetes',
        type: 'checkbox'
      },
      {
        name: 'jaundice',
        type: 'checkbox'
      },
      {
        name: 'hepatitis',
        type: 'checkbox'
      },
      {
        name: 'haemophilia',
        type: 'checkbox'
      },
      {
        name: 'family_history_haemophilia',
        type: 'checkbox'
      },
      {
        name: 'sickle_cell_anaemia',
        type: 'checkbox'
      },
      {
        name: 'family_history_sickle_cell_anaemia',
        type: 'checkbox'
      }
    ]
  },
  {
    name: 'more_infos',
    questions: [
      {
        name: 'medication',
        type: 'radio'
      },
      {
        name: 'blood_tests',
        type: 'radio'
      },
      {
        name: 'under_care_prolonged_illness',
        type: 'radio'
      },
      {
        name: 'blood_borne_viruses',
        type: 'radio'
      },
      {
        name: 'exposed_to_ionising_radiation_head_neck_region',
        type: 'radio'
      },
      {
        name: 'artificial_joints',
        type: 'radio'
      },
      {
        name: 'medication_osteoporosis',
        type: 'radio'
      },
      {
        name: 'pregnant',
        type: 'radio'
      },
      {
        name: 'smoking',
        type: 'radio'
      },
      {
        name: 'alcohol',
        type: 'radio'
      },
      {
        name: 'has_allergies',
        type: 'textfield',
        textfieldName: 'allergies'
      },
      {
        name: 'has_other_concerns',
        type: 'textfield',
        textfieldName: 'other_concerns'
      }
    ]
  }
];

export default questionsByCategories;

// Mes dents ont été nettoyées par un dentiste au cours des 6 derniers mois
// EN : I had my teeth professionally cleaned by my dentist in the last 6 months
// Une radiographie panoramique de mes dents a été prise au cours des 12 derniers mois
// EN : I had x-rays taken of my teeth in the last 12 months
// J’ai fait examiner mes dents à la recherche de carries et/ou de plombages endommagés, et tout problème détecté a été réglé
// EN : I had my teeth checked for decay, loose & broken fillings, and any issues detected have been fixed
// Mes radiographies n’ont pas révélé de racine(s) dentaire(s) raccourcie(s) ou résorbée(s)
// EN : I had my x-rays checked and I have no shortened or resorbed tooth roots
// Mes radiographies n’ont pas révélé de dent(s) éruptée(s), semi-incluse(s) ou incluse(s)
// EN : I had my x-rays checked and I have no impacted/non-erupted teeth in my jaws
// Mon examen dentaire par un dentiste n’a pas révélé de maladie des gencives
// EN : I have my mouth checked by a dentist and I do not have gum disease
// Mon examen dentaire par un dentiste n’a pas révélé de cancer oral
// EN : I have my mouth checked for oral cancer by a dentist and I do not have oral cancer
// Je n’ai des douleurs au niveau d’aucune de mes dents
// EN : I have no pain in any of my teeth
// Je n’ai pas de douleurs au niveau de la mâchoire ou des difficultés à l’ouvrir/fermer
// EN : I have no pain in my jaws or difficulty in chewing
// Je n’ai jamais été diagnostiqué d’un trouble de l'articulation temporo-mandibulaire
// EN : I have never been diagnosed with TMJ - Temporomandibular Joint Disorder
// Je n’ai pas de dents qui bougent
// EN : I have no loose teeth
// Je n’ai pas de couronnes, bridges ou facettes sur les dents de devant
// EN : I have no implants, crowns, bridges or veneers on the front teeth (6 front teeth)
// Je n’ai pas d’implants, couronnes, bridges ou facettes
// EN : I have no dental implants, crowns, bridges or veneers
// Fièvre rhumatismale (Sydenham's Chorea/St Vitus' Dance)
// EN : Rheumatic fever (Chorea St Vitus Dance)
// Angine
// EN : Angina
// Chirurgie du Cœur
// EN : Heart surgery
// Infarctus
// EN : Heart attack
// Tension artérielle basse/haute
// EN : High / Low Blood Pressure
// Pacemaker
// EN : Pacemaker
// Souffle cardiaque
// EN : Heart murmur
// Valvules cardiaques artificielles
// EN : Replacement heart valve
// Asthme
// EN : Asthma
// Bronchite
// EN : Bronchitis
// Tuberculose
// EN : Tuberculosis
// Epilepsie
// EN : Epilepsy
// Diabètes
// EN : Diabetes
// Antécédents familiaux – Diabètes
// EN : Family History of Diabetes
// Jaunisse
// EN : Jaundice
// Hépatite
// EN : Hepatitis
// Hémophilie ou problèmes de saignement
// EN : Haemophilia or other bleeding problems
// Antécédents familiaux - Hémophilie ou problèmes de saignement
// EN : Family history of haemophilia or other bleeding problems
// Allergies (ex : pénicilline, pansement…)
// EN : Allergies (e.g. penicillin, plasters)
// Drépanocytose ou anémie à hématies falciformes
// EN : Sickle Cell anaemia
// Antécédents familiaux – Drépanocytose
// EN : Family history of Sickle Cell anaemia
// Prenez-vous actuellement des médicaments ? (ex : injections, comprimés, inhalateurs, pommade)
// EN : Are you taking any medication? (e.g. injections, tablets, inhalers, ointments)
// Avec-vous récemment effectué des tests sanguins ou autres investigations ?
// EN : Have you undergone any blood tests or other investigations?
// Etes-vous actuellement/avez été sous traitement de votre médecin et/ou malade sur une période prolongée et/ou hospitalisé(e) ?
// EN : Are you currently / have you recently been under the care of your Doctor? Have had a prolonged illness or being hospitalised?
// Êtes-vous porteur d’un virus à diffusion hématogène ? (ex : hépatite B, hépatite C, HIV)
// EN : Do you carry any blood borne viruses? (e.g. hepatitis B, hepatitis C, hiV)
// Avez-vous été exposé a des radiations ionisantes au niveau de la tête ou du cou ?
// EN : Have you been exposed to ionising radiation in the head and neck region?
// Avez-vous une prothèse articulaire ? (ex : hanche, genou)
// EN : Do you have any artificial joints? (e.g. hip, knee)
// Prenez-vous des médicaments pour traiter l’ostéoporose ? (ex : bisphosphonates)
// EN : Are you taking any medication for osteoporosis? (e.g. bisphosphonates)
// Femmes uniquement : êtes-vous ou pensez-vous être enceinte ?
// EN : Women only: Do you know, or suspect you might be pregnant?
// Fumez-vous ?
// EN : Do you smoke?
// Buvez-vous de l’alcool ?
// EN : Do you drink alcohol?
// Avez-vous des allergies ?
// EN : Are you allergic to anything? Please enter what.
// Avez-vous un trouble médical dont vous aimeriez discuter personnellement avec votre orthodontiste ?
// EN : Is there any medical condition that you need to discuss personally with your Dentist?
