import { Grid, RadioGroup } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { STRIPE_METHOD } from '../../../../utils/pledg.utils';
import PaymentMethod from './PaymentMethod';
import {
  StyledFormControl,
  StyledFormLabel,
  StyledPaymentMethodContainer
} from './PaymentMethods.styled';

const SelectPaymentMethod = ({
  onSelect,
  disabled,
  activePaymentMethod,
  buttonRef,
  paymentMethods
}) => {
  const { t } = useTranslation('production');

  const pledgPlans = paymentMethods.filter((pm) => pm.name.includes('pledg'));

  return (
    <StyledFormControl component="fieldset" disabled={disabled}>
      <StyledFormLabel component="legend">
        {t('production:production.payment.methods.select')}
      </StyledFormLabel>
      <RadioGroup
        defaultValue="stripe"
        aria-label="methode de paiement"
        name="payment-method"
      >
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3} md={3} lg={2}>
            <PaymentMethod
              elementRef={
                activePaymentMethod === STRIPE_METHOD ? buttonRef : undefined
              }
              label={t('production:production.payment.methods.cash')}
              plan={{
                name: STRIPE_METHOD
              }}
              onClick={() => onSelect(STRIPE_METHOD)}
              checked={activePaymentMethod === STRIPE_METHOD}
            />
          </Grid>
          {pledgPlans.map((plan) => (
            <StyledPaymentMethodContainer
              item
              xs={6}
              sm={3}
              md={3}
              lg={2}
              key={plan.name}
            >
              <PaymentMethod
                elementRef={
                  activePaymentMethod === plan.name ? buttonRef : undefined
                }
                label={t(`production:production.payment.methods.${plan.name}`)}
                plan={plan}
                onClick={() => onSelect(plan.name)}
                checked={activePaymentMethod === plan.name}
              />
            </StyledPaymentMethodContainer>
          ))}
        </Grid>
      </RadioGroup>
    </StyledFormControl>
  );
};

export default SelectPaymentMethod;
