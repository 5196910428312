import React from 'react';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import SendIcon from '@material-ui/icons/Send';

import { Form, Field } from 'react-final-form';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import Checkbox from './form/Checkbox';

import Upload from './Upload';
import Loadable from './Loadable';
import Rating from './Rating';

import axios from '../axios';
import {
  getAnglesFromVersion,
  VERSION_INITIAL
} from '../constants/photos.constant';

const styles = (theme) => ({
  submit: {
    marginTop: theme.spacing(2)
  },
  icon: {
    marginLeft: theme.spacing()
  },
  hint: {
    width: '100%',
    maxWidth: '800px'
  }
});

const PhotosUpload = ({
  serie,
  patient,
  classes,
  auth,
  snackbar,
  readOnly,
  onSuccess,
  allowNoPhotos,
  aligner,
  t
}) => {
  const angles = getAnglesFromVersion(
    (aligner && aligner.version) || VERSION_INITIAL
  );
  const initialValues = Object.assign(
    {
      global_satisfaction: 5,
      fitting_upper: 5,
      fitting_lower: 5,
      pain_upper: 0,
      pain_lower: 0,
      remarks: aligner && aligner.remarks
    },
    ...angles.map((angle) => ({
      [angle.name]: false
    }))
  );

  const validate = (values) => {
    const errors = {};

    if (values.has_no_photos && allowNoPhotos) {
      return errors;
    }

    angles.forEach((angle) => {
      if (!values[angle.name]) {
        errors[angle.name] = t('common:common.mandatory');
      }
    });

    return errors;
  };

  const handleSubmit = async (values) => {
    try {
      await axios.post(`aligners/${serie}/request-review`, values);

      auth.fetchUser();
      snackbar.open(t('photoUpload:photoUpload.snackbar.success'), 'success');
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      snackbar.open(t('photoUpload:photoUpload.snackbar.error'), 'error');
    }
  };

  if (aligner && !aligner.finished_at && !aligner.can_be_changed) return null;

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({
        handleSubmit: onSubmit,
        submitting,
        hasValidationErrors,
        form,
        values
      }) => (
        <form onSubmit={onSubmit}>
          <Grid container spacing={3} justifyContent="center">
            {aligner && (
              <Grid item xs={12}>
                <Rating
                  aligner={aligner}
                  readOnly={readOnly}
                  form={form}
                  values={values}
                />
              </Grid>
            )}

            {allowNoPhotos && !readOnly && (
              <Grid item xs={12}>
                <Field
                  name="has_no_photos"
                  label={`${t(
                    'photoUpload:photoUpload.hasNoPhotos.label'
                  )} ${serie}`}
                  component={Checkbox}
                  type="checkbox"
                />
              </Grid>
            )}
            {!values.has_no_photos &&
              angles.map((angle, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Upload
                    receiverId={patient.id}
                    receiverType="patient"
                    type="treatment_photo"
                    backgroundImage={angle.background}
                    meta={{
                      angle: angle.name,
                      serie,
                      ...(aligner && { version: aligner.version })
                    }}
                    fileName={`photo-${angle.name}-${serie}-${patient.reference}`}
                    description={angle.display}
                    retryable
                    autoUpload
                    onSuccess={() => {
                      form.change(angle.name, true);
                    }}
                    readOnly={readOnly}
                    hint={
                      angle.angle && (
                        <img
                          alt={angle.display}
                          className={classes.hint}
                          src={angle.hint}
                        />
                      )
                    }
                  />
                </Grid>
              ))}
            <Grid item xs={12} />
            <Grid item xs={12} sm={6}>
              {!readOnly && (
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={submitting || hasValidationErrors}
                  fullWidth
                >
                  <Loadable loading={submitting} noMessage size={18}>
                    {t('common:common.send')}{' '}
                    <SendIcon className={classes.icon} />
                  </Loadable>
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};
const PhotosUploadWithStyles = withStyles(styles)(PhotosUpload);
export default withTranslation(['photoUpload', 'common'])(
  PhotosUploadWithStyles
);
