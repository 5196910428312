import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { Form, Field } from 'react-final-form';

import parseISO from 'date-fns/parseISO';
import { withTranslation } from 'react-i18next';
import Select from '../form/Select';

class DoctolibConfirm extends Component {
  state = {
    editMode: false
  };

  setEditMode = (editMode) => {
    this.setState({ editMode });
  };

  render() {
    const { editMode } = this.state;
    const { booking, availabilities, getHours, setBooking, t } = this.props;

    return (
      <div>
        {editMode ? (
          <Form
            onSubmit={() => {}}
            initialValues={{
              date: booking.date,
              hour: booking.hour
            }}
            render={({ values, form }) => {
              return (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1">
                      {t('bookings:bookings.doctolib.anotherDate.title')}
                    </Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Field
                      name="date"
                      label={t('bookings:bookings.doctolib.anotherDate.date')}
                      formControlProps={{
                        fullWidth: true
                      }}
                      component={Select}
                      onSelect={(event) => {
                        setBooking({
                          ...booking,
                          date: event.target.value,
                          hour: getHours(event.target.value)[0]
                        });

                        form.change('hour', getHours(event.target.value)[0]);
                      }}
                    >
                      {availabilities.map((availability) => (
                        <MenuItem
                          key={availability.date}
                          value={availability.date}
                        >
                          {t('common:common.date.detailed', {
                            date: parseISO(availability.date)
                          })}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <Field
                      name="hour"
                      label={t('bookings:bookings.doctolib.anotherDate.time')}
                      formControlProps={{
                        fullWidth: true
                      }}
                      component={Select}
                      onSelect={(event) => {
                        setBooking({
                          ...booking,
                          hour: event.target.value
                        });
                      }}
                    >
                      {getHours(values.date).map((slot) => (
                        <MenuItem key={slot} value={slot}>
                          {slot}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                </Grid>
              );
            }}
          />
        ) : (
          <Button
            size="small"
            onClick={() => this.setEditMode(true)}
            color="primary"
          >
            {t('bookings:bookings.doctolib.anotherDate.button')}
          </Button>
        )}
      </div>
    );
  }
}

export default withTranslation('bookings')(DoctolibConfirm);
