import { COUNTRIES } from '../../utils/vars';

export const getReferralLink = (auth) => {
  switch (auth.user.address[0]?.country) {
    case COUNTRIES.COUNTRY_ES:
      return 'https://www.rewardsgenius.com/reward-link-spain/';
    case COUNTRIES.COUNTRY_BE:
      return 'https://www.rewardsgenius.com/reward-link-belgium/';
    case COUNTRIES.COUNTRY_FR:
    default:
      return 'https://www.rewardsgenius.com/reward-link-france/';
  }
};
