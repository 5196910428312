import React from 'react';
import { Grid, Button } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import TextField from '../form/TextField';

const CouponForm = ({ initialValues, onSubmit, coupon }) => {
  const { t } = useTranslation(['coupons', 'common']);
  const validate = (values) =>
    !values.code ? { code: t('common:common.mandatory') } : {};
  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit: handleSubmitCoupon, submitting }) => (
        <form onSubmit={handleSubmitCoupon}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Field
                fullWidth
                name="code"
                label={t('coupons:coupons.add.label')}
                component={TextField}
                disabled={submitting || coupon}
              />
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={submitting}
              >
                {t('coupons:coupons.add.apply')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    />
  );
};

export default CouponForm;
